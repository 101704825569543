import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { ArchiveModal, Kanban } from "@organisms"
import { Trans } from "react-i18next"
import { useTheme } from "styled-components"
import { useResignationKanban } from "./hooks/useResignationKanban"
import { KanbanStepHeader } from "./KanbanStepHeader"
import { EllipsisText } from "./KanbanStepHeader/styled"
import { LoadingState } from "./LoadingState"
import { ResignationCard } from "./ResignationCard"

export const ResignationKanban = () => {
  const theme = useTheme()

  const {
    kanbanFlowColumns,
    isLoading,
    onKanbanDragEnd,
    archiveModal,
    onArchiveModalClose,
  } = useResignationKanban()

  if (isLoading) {
    return <LoadingState />
  }

  if (!kanbanFlowColumns?.length) {
    return <></>
  }

  return (
    <>
      <Kanban.Root onDragCardEnd={onKanbanDragEnd}>
        {kanbanFlowColumns.map(
          ({ cards, id, title, description, columnType }) => (
            <Kanban.Column key={id} id={id} columnType={columnType}>
              <Kanban.Header>
                <KanbanStepHeader
                  title={title}
                  description={description}
                  cardsCountDescription={
                    <Trans
                      i18nKey={"page.home.kanban.cardsCountDescription"}
                      values={{ cardsCount: cards.length }}
                      components={{
                        display: (
                          <Typography
                            variant="body2"
                            weight={700}
                            variantColor={theme.colors.neutral[50]}
                          >
                            {""}
                          </Typography>
                        ),
                        description: (
                          <EllipsisText
                            variant="body4"
                            weight={600}
                            variantColor={theme.colors.neutral[50]}
                            lines={1}
                          >
                            {""}
                          </EllipsisText>
                        ),
                      }}
                    />
                  }
                />
              </Kanban.Header>
              <Kanban.CardsList>
                {cards.map(
                  (
                    { bottomText, id, onClick, subtitle, title, tagProps },
                    index,
                  ) => {
                    return (
                      <Kanban.Card
                        key={id}
                        id={id}
                        index={index}
                        onClick={onClick}
                      >
                        <ResignationCard
                          subtitle={subtitle}
                          title={title}
                          tagProps={tagProps}
                          bottomText={bottomText}
                        />
                      </Kanban.Card>
                    )
                  },
                )}
              </Kanban.CardsList>
            </Kanban.Column>
          ),
        )}
      </Kanban.Root>

      {archiveModal.open && (
        <ArchiveModal
          visible={archiveModal.open}
          onClose={onArchiveModalClose}
          onConfirm={archiveModal.callback}
        />
      )}
    </>
  )
}
