import { Modal } from "@flash-tecnologia/flamingo-ui-modal"
import { Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2"

import { useTranslation } from "react-i18next"

type ArchiveModalProps = {
  visible: boolean
  onClose: () => void
  onConfirm?: () => void
  isLoading?: boolean
}

export const ArchiveModal = ({
  visible,
  onClose,
  onConfirm,
  isLoading = false,
}: ArchiveModalProps) => {
  const [t] = useTranslation("translations", {
    keyPrefix: "page.components.archiveModal",
  })

  return (
    <Modal.Root
      size="small"
      open={visible}
      onOpenChange={(open) => !open && onClose()}
    >
      <Modal.Content.Root>
        <Modal.Content.Highlight type="warning" size="default">
          <Typography variant="body4" weight={700}>
            {t("highlight")}
          </Typography>
        </Modal.Content.Highlight>

        <Modal.Content.Title>{t("title")}</Modal.Content.Title>
        <Modal.Content.Description>
          <Typography variant="body4">{t("description")}</Typography>
        </Modal.Content.Description>

        <Modal.Content.Footer>
          <Modal.Content.Footer.CancelButton onClick={onClose}>
            {t("buttons.cancel")}
          </Modal.Content.Footer.CancelButton>
          <Modal.Content.Footer.PrimaryButton
            onClick={() => {
              onConfirm?.()
              onClose()
            }}
            loading={isLoading}
            variantType="error"
          >
            <>
              {t("buttons.confirm")}
              <Icons name="IconArchive" fill="transparent" />
            </>
          </Modal.Content.Footer.PrimaryButton>
        </Modal.Content.Footer>
      </Modal.Content.Root>
    </Modal.Root>
  )
}
