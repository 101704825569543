import { trpc } from "@api/client"
import { FlowColumn } from "@customTypes/flow"
import { ExitFormEnum, ExitTermsEnum } from "@customTypes/resignation"
import i18n from "@i18n"
import { useFormik } from "formik"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import * as yup from "yup"
import { useEmployeeOptions } from "./useEmployeeOptions"
import dispatchToast from "@utils/dispatchToast"

type EmployeeResignationForm = {
  employee: { id: string; name: string } | undefined
  responsible: { id: string; name: string } | undefined
  followers: { id: string; name: string }[] | undefined
  exitForm: ExitFormEnum | undefined
  exitTerms: ExitTermsEnum | undefined
  email: string
  requestDate: string | undefined
  noticePeriod: boolean
  terminationDate: string | undefined
  motivation: string
}

const requiredError = i18n.t(
  "components.upsertEmployeeModal.form.errors.required",
)

const validationSchema = yup.object({
  employee: yup.object().required(requiredError),
  responsible: yup.object().required(requiredError),
  followers: yup.array(yup.object()).notRequired(),
  exitForm: yup.string().required(requiredError),
  exitTerms: yup.string().required(requiredError),
  email: yup.string().notRequired(),
  requestDate: yup.string().required(requiredError),
  noticePeriod: yup.boolean().required(requiredError),
  terminationDate: yup.string().required(requiredError),
  motivation: yup.string().notRequired(),
})

const getResignationEmployees = (flow: FlowColumn[] | undefined): string[] => {
  if (!flow) return []
  const result: string[] = []
  flow.forEach((column) =>
    column.cards.forEach((card) => result.push(card.employeeId)),
  )
  return result
}

type UpsertEmployeeModalViewModelProps = {
  onClose: () => void
}

export const useUpsertEmployeeModalViewModel = ({
  onClose,
}: UpsertEmployeeModalViewModelProps) => {
  const [t] = useTranslation("translations", {
    keyPrefix: "components.upsertEmployeeModal",
  })

  const [employeeSearchTerm, setEmployeeSearchTerm] = useState("")
  const [responsibleSearchTerm, setResponsibleSearchTerm] = useState("")
  const [followersSearchTerm, setFollowersSearchTerm] = useState("")

  const utils = trpc.useUtils()

  const flow = utils.flow.getFlow.getData()

  const formik = useFormik<EmployeeResignationForm>({
    initialValues: {
      employee: undefined,
      responsible: undefined,
      followers: undefined,
      exitForm: undefined,
      exitTerms: undefined,
      email: "",
      requestDate: undefined,
      noticePeriod: true,
      terminationDate: undefined,
      motivation: "",
    },
    onSubmit: async (values) => {
      if (
        !values.employee ||
        !values.responsible ||
        !values.exitForm ||
        !values.exitTerms ||
        !values.requestDate ||
        !values.terminationDate
      ) {
        dispatchToast({
          type: "error",
          content: t("form.errors.checkFields"),
        })
        return
      }
      await createResignationCard({
        employeeId: values.employee.id,
        responsibleId: values.responsible.id,
        followers: values.followers?.map(({ id }) => id),
        exitForm: values.exitForm,
        exitTerms: values.exitTerms,
        email: values.email,
        requestDate: values.requestDate,
        noticePeriod: values.noticePeriod,
        terminationDate: values.terminationDate,
        motivation: values.motivation,
      })
      formik.resetForm()
      onClose()
    },
    validationSchema: validationSchema,
    validate: (values) => {
      const errors: { [key in keyof EmployeeResignationForm]?: string } = {}

      const employeeIdsToFilter = getResignationEmployees(flow)
      if (values.employee && employeeIdsToFilter.includes(values.employee.id)) {
        errors.employee = t("form.errors.employeeIsAlreadyAdded")
      }
      if (
        values.responsible &&
        employeeIdsToFilter.includes(values.responsible.id)
      ) {
        errors.responsible = t("form.errors.responsibleIsOnResignation")
      }
      if (
        values.followers?.some(({ id }) => employeeIdsToFilter.includes(id))
      ) {
        errors.followers = t("form.errors.followerIsOnResignation")
      }
      if (
        values.employee &&
        values.responsible &&
        values.employee.id === values.responsible.id
      ) {
        errors.responsible = t("form.errors.invalidResponsible")
      }

      return errors
    },
  })

  const handleClose = () => {
    formik.resetForm()
    onClose()
  }

  const {
    employeeOptions,
    isLoading: isEmployeeOptionsLoading,
    debouncedSearchTerm: debouncedEmployeeSearchTerm,
  } = useEmployeeOptions({
    searchTerm: employeeSearchTerm,
    employeeIdsToFilter: [formik.values.responsible?.id ?? ""],
  })

  const {
    employeeOptions: responsibleOptions,
    isLoading: isResponsibleOptionsLoading,
    debouncedSearchTerm: debouncedResponsibleSearchTerm,
  } = useEmployeeOptions({
    searchTerm: responsibleSearchTerm,
    employeeIdsToFilter: [formik.values.employee?.id ?? ""],
  })

  const {
    employeeOptions: followersOptions,
    isLoading: isFollowersOptionsLoading,
    debouncedSearchTerm: debouncedFollowersSearchTerm,
  } = useEmployeeOptions({
    searchTerm: followersSearchTerm,
    employeeIdsToFilter: [formik.values.employee?.id ?? ""],
  })

  const {
    mutateAsync: createResignationCard,
    isLoading: isCreateResignationCardLoading,
  } = trpc.flow.createResignationCard.useMutation({
    onSuccess: () => {
      utils.flow.getFlow.invalidate()
      handleClose()
    },
    onError: (error) => {
      dispatchToast({
        type: "error",
        content:
          error.data?.userFriendlyError.message ??
          i18n.t("error.internalServerError"),
      })
    },
  })

  return {
    form: {
      values: formik.values,
      handleChange: <K extends keyof EmployeeResignationForm>(
        key: K,
        value: EmployeeResignationForm[K],
      ) => {
        formik.handleChange({ target: { name: key, value } })
      },
      touched: formik.touched,
      errors: formik.errors,
      handleSubmit: formik.handleSubmit,
    },
    formik,
    setEmployeeSearchTerm,
    debouncedEmployeeSearchTerm,
    employeeOptions,
    isEmployeeOptionsLoading,
    setResponsibleSearchTerm,
    debouncedResponsibleSearchTerm,
    responsibleOptions,
    isResponsibleOptionsLoading,
    setFollowersSearchTerm,
    debouncedFollowersSearchTerm,
    followersOptions,
    isFollowersOptionsLoading,
    isCreateResignationCardLoading,
    handleClose,
  }
}
