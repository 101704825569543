import { ReactNode } from "react"
import * as SC from "./styles"

export interface LeftContentProps {
  children: ReactNode
}

export const LeftContent = ({ children }: LeftContentProps) => {
  return <SC.LeftContentWrapper>{children}</SC.LeftContentWrapper>
}
