import { File, Steps } from "@customTypes/resignation"
import dispatchToast from "@utils/dispatchToast"
import { downloadFile } from "@utils/downloadFile"
import { useFormik } from "formik"
import { ForwardedRef, useEffect, useImperativeHandle } from "react"
import { useTranslation } from "react-i18next"
import * as yup from "yup"
import { useEmailInstructionsFormContext } from "../components/FormalizationStep"
import { EmailInstructionsFormRef } from "./useFormalizationStep"

export interface EmailInstructionsForm {
  email?: string
  attachment?: File
  sendingLetterDeadline?: Date
}

export function useEmailInstructionsForm({
  emailInstructionsFormRef,
  initialEmail,
  initialAttachment,
  initialSendingLetterDeadline,
}: {
  emailInstructionsFormRef: ForwardedRef<EmailInstructionsFormRef>
  initialEmail?: string
  initialAttachment?: File
  initialSendingLetterDeadline?: Date
}) {
  const [t] = useTranslation("translations", {
    keyPrefix: `page.resignationStep.${Steps.Formalization}.emailInstructionsSection`,
  })

  const { setFormValues } = useEmailInstructionsFormContext()

  const formik = useFormik<EmailInstructionsForm>({
    initialValues: {
      attachment: initialAttachment,
      sendingLetterDeadline: initialSendingLetterDeadline,
      email: initialEmail,
    },
    onSubmit: () => {},
    validationSchema: yup.object({
      email: yup
        .string()
        .email(t("form.validation.errors.invalidEmail"))
        .required(t("form.validation.errors.required")),
      attachment: yup.object(),
    }),
  })

  const handleEmailChange = (email: string) => {
    formik.handleChange({ target: { name: "email", value: email } })
  }

  const handleEmailBlur = () => {
    formik.handleBlur({ target: { name: "email" } })
  }

  const handleAttachmentUpload = (file: File) => {
    formik.handleChange({ target: { name: "attachment", value: file } })
  }

  const handleAttachmentDelete = () => {
    formik.handleChange({ target: { name: "attachment", value: undefined } })
  }

  const handleAttachmentDownload = ({
    fileName,
    url,
  }: {
    url?: string
    fileName?: string
  }) => {
    if (!url) {
      dispatchToast({
        type: "error",
        content: t("uploader.messages.error.download"),
      })
      return
    }

    downloadFile(url, fileName ?? t("uploader.defaultFileName"))
  }

  useImperativeHandle(emailInstructionsFormRef, () => ({
    onAttachmentDeleteSuccess: handleAttachmentDelete,
    onAttachmentUploadSuccess: handleAttachmentUpload,
    validate: formik.validateForm,
  }))

  useEffect(() => {
    setFormValues(formik.values)
  }, [setFormValues, formik.values])

  return {
    attachment: formik.values.attachment,
    email: formik.values.email,
    emailError: formik.errors.email,
    onEmailBlur: handleEmailBlur,
    onEmailChange: handleEmailChange,
    onAttachmentDownload: handleAttachmentDownload,
  }
}
