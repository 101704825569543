import { Box } from "@atoms/Box"
import { ReactNode } from "react"
import { CSSProperties, useTheme } from "styled-components"

interface CardProps {
  $width?: CSSProperties["width"]
  children: ReactNode
}

export const Card = ({ $width, children }: CardProps) => {
  const theme = useTheme()
  return (
    <Box
      $border={`${theme.borders.width.xs2} solid ${theme.colors.neutral[90]}`}
      $borderRadius={theme.borders.radius.m}
      $px={"m"}
      $py={"m"}
      $width={$width}
    >
      {children}
    </Box>
  )
}
