import { Box } from "@atoms"
import { Skeleton } from "@flash-tecnologia/hros-web-ui-v2"

export const LoadingState = () => {
  return (
    <Box $height={"100%"} flexDirection="column">
      <Box $px="xs" $py="xs3">
        <Skeleton variant="rectangular" width={"30%"} height={"40px"} />
      </Box>
      <Box $px="xl4" $py="m" flexDirection="column" gap="xs" flex={1}>
        <Skeleton variant="rectangular" width={"15%"} height={"30px"} />
        <Skeleton variant="rectangular" width={"25%"} height={"40px"} />
        <Skeleton variant="rectangular" width={"55%"} height={"30px"} />
        <Box gap="xs">
          <Skeleton variant="rectangular" width={"30%"} height={"60px"} />
          <Skeleton variant="rectangular" width={"30%"} height={"60px"} />
          <Skeleton variant="rectangular" width={"30%"} height={"60px"} />
        </Box>
        <Box gap="m" $width={"100%"} $height={"100%"}>
          <Skeleton variant="rectangular" width={"70%"} height={"100%"} />
          <Skeleton variant="rectangular" width={"30%"} height={"100%"} />
        </Box>
      </Box>
      <Box $px="xs" $py="xs3" justifyContent="space-between">
        <Skeleton variant="rectangular" width={"20%"} height={"50px"} />
        <Skeleton variant="rectangular" width={"30%"} height={"50px"} />
      </Box>
    </Box>
  )
}
