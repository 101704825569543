import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import * as SC from "./styles"

export interface DisclaimerProps {
  children: string
}

export const Disclaimer = ({ children }: DisclaimerProps) => {
  return (
    <SC.DisclaimerWrapper>
      <Typography.Body4 color={"neutral.50"} weight={600}>
        {children}
      </Typography.Body4>
    </SC.DisclaimerWrapper>
  )
}
