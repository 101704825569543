import { EvaluationStatus, FormalizationStatus } from "@customTypes/resignation"
import { TagProps } from "@pages/ResignationStep/types"
import i18n from "@i18n"

const mapStatusToTags: Record<string, TagProps> = {
  [EvaluationStatus.NotStarted]: {
    as: "span",
    children: i18n.t(
      `page.components.resignationStatusTag.${EvaluationStatus.NotStarted}`,
    ),
    variant: "gray",
  } as TagProps,
  [EvaluationStatus.InProgress]: {
    as: "span",
    children: i18n.t(
      `page.components.resignationStatusTag.${EvaluationStatus.InProgress}`,
    ),
    variant: "info",
  } as TagProps,
  [EvaluationStatus.Done]: {
    as: "span",
    children: i18n.t(
      `page.components.resignationStatusTag.${EvaluationStatus.Done}`,
    ),
    variant: "success",
  } as TagProps,
  [FormalizationStatus.PendingEmail]: {
    as: "span",
    children: i18n.t(
      `page.components.resignationStatusTag.${FormalizationStatus.PendingEmail}`,
    ),
    variant: "error",
  } as TagProps,
  [FormalizationStatus.InProgress]: {
    as: "span",
    children: i18n.t(
      `page.components.resignationStatusTag.${FormalizationStatus.InProgress}`,
    ),
    variant: "info",
  } as TagProps,
  [FormalizationStatus.Done]: {
    as: "span",
    children: i18n.t(
      `page.components.resignationStatusTag.${FormalizationStatus.Done}`,
    ),
    variant: "success",
  } as TagProps,
}

export const mapResignationStatusToTagProps = (status: string): TagProps =>
  mapStatusToTags[status]
