import { trpc } from "@api/client"
import {
  ExitFormEnum,
  File,
  Formalization,
  FormalizationStatus,
  Steps,
} from "@customTypes/resignation"
import i18n from "@i18n"
import { dayjs } from "@utils"
import dispatchToast from "@utils/dispatchToast"
import { isEmpty } from "lodash-es"
import { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  useEmailInstructionsFormContext,
  useLetterFormContext,
} from "../components/FormalizationStep"
import { LetterForm } from "../components/LetterSection"
import { FormErrors } from "../types"
import { EmailInstructionsForm } from "./useEmailInstructionsForm"

export interface EmailInstructionsFormRef
  extends FormRef<EmailInstructionsForm> {
  onAttachmentDeleteSuccess: () => void
  onAttachmentUploadSuccess: (file: File) => void
}

export interface FormRef<T> {
  validate: () => FormErrors<T> | undefined
}

export function useFormalizationStep({
  initialResignation,
}: {
  initialResignation: {
    exitForm: ExitFormEnum
    formalization: Formalization
    id: string
    email?: string
  }
}) {
  const [t] = useTranslation("translations", {
    keyPrefix: `page.resignationStep.${Steps.Formalization}`,
  })

  const utils = trpc.useUtils()

  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState<boolean>()

  const letterSectionFormRef = useRef<FormRef<LetterForm>>(null)
  const emailInstructionsFormRef = useRef<EmailInstructionsFormRef>(null)

  const { formValues: emailInstructionsFormValues } =
    useEmailInstructionsFormContext()
  const { formValues: letterFormValues } = useLetterFormContext()

  const { mutateAsync: updateFormalization, error: updateFormalizationError } =
    trpc.resignation.updateFormalization.useMutation({
      onError: (error) => {
        dispatchToast({
          type: "error",
          content:
            error.data?.userFriendlyError.message ??
            i18n.t("error.internalServerError"),
        })
        setIsSubmitButtonLoading(false)
      },
    })

  const { mutateAsync: requestLetter } =
    trpc.resignation.requestLetter.useMutation({
      onError: (error) => {
        dispatchToast({
          type: "error",
          content:
            error.data?.userFriendlyError.message ??
            i18n.t("error.internalServerError"),
        })
      },
      onSuccess: () => {
        dispatchToast({
          type: "success",
          content: t("submit.messages.requestLetterSuccess"),
        })
        utils.resignation.getResignation.invalidate()
      },
    })

  const { mutateAsync: sendInstructions } =
    trpc.resignation.sendInstructions.useMutation({
      onError: (error) => {
        dispatchToast({
          type: "error",
          content:
            error.data?.userFriendlyError.message ??
            i18n.t("error.internalServerError"),
        })
        setIsSubmitButtonLoading(false)
      },
      onSuccess: () => {
        dispatchToast({
          type: "success",
          content: t("form.messages.success"),
        })
        utils.resignation.getResignation.invalidate()
      },
    })

  const handleSubmit = async () => {
    setIsSubmitButtonLoading(true)
    try {
      if (initialResignation.exitForm === ExitFormEnum.Voluntary) {
        const letterSectionFormErrors =
          await letterSectionFormRef.current?.validate()

        if (!isEmpty(letterSectionFormErrors)) {
          dispatchToast({
            type: "error",
            content: t("submit.messages.invalidLetterSectionForm"),
          })
          setIsSubmitButtonLoading(false)
          return
        }
      }

      const emailInstructionsFormErrors =
        await emailInstructionsFormRef.current?.validate()

      if (!isEmpty(emailInstructionsFormErrors)) {
        dispatchToast({
          type: "error",
          content: t("submit.messages.invalidEmailInstructionsForm"),
        })
        setIsSubmitButtonLoading(false)
        return
      }

      if (!emailInstructionsFormValues?.email) {
        dispatchToast({
          type: "error",
          content: t("submit.messages.missingEmail"),
        })
        setIsSubmitButtonLoading(false)
        return
      }

      if (letterFormValues?.shouldRequestLetter) {
        await requestLetter({
          email: emailInstructionsFormValues.email,
          resignationId: initialResignation.id,
          dueDate:
            letterFormValues.sendingLetterDeadline &&
            dayjs(letterFormValues.sendingLetterDeadline).toISOString(),
        })
      } else {
        await sendInstructions({
          resignationId: initialResignation.id,
          email: emailInstructionsFormValues.email,
          attachment: emailInstructionsFormValues.attachment,
        })
      }
      setIsSubmitButtonLoading(false)
    } catch (error) {
      setIsSubmitButtonLoading(false)
      throw error
    }
  }

  const handleAttachmentUpload = async (file: File) => {
    if (!emailInstructionsFormValues?.email) {
      dispatchToast({
        type: "error",
        content: t("submit.messages.missingEmail"),
      })
      return
    }
    await updateFormalization({
      attachment: { path: file.path },
      email: emailInstructionsFormValues.email,
      resignationId: initialResignation.id,
    })
    emailInstructionsFormRef.current?.onAttachmentUploadSuccess(file)
  }

  const handleAttachmentDelete = async () => {
    await updateFormalization({
      attachment: undefined,
      resignationId: initialResignation.id,
    })
    emailInstructionsFormRef.current?.onAttachmentDeleteSuccess()
  }

  const displaySubmitButton =
    initialResignation.formalization.status !== FormalizationStatus.Done

  const submitButtonText = !!letterFormValues?.shouldRequestLetter
    ? t("submit.button.requestLetter")
    : t("submit.button.emailInstructions")

  return {
    attachmentUploadError: !!updateFormalizationError,
    displaySubmitButton,
    emailInstructionsFormRef,
    isSubmitButtonLoading,
    letterSectionFormRef,
    submitButtonText,
    onAttachmentDelete: handleAttachmentDelete,
    onAttachmentUpload: handleAttachmentUpload,
    onSubmit: handleSubmit,
  }
}
