import { Box } from "@atoms/Box"
import { ReactElement } from "react"
import { DefinitionDescription } from "./DefinitionDescription"
import { DefinitionTitle } from "./DefinitionTitle"

export interface DefinitionListItemProps {
  children: ReactElement<typeof DefinitionTitle | typeof DefinitionDescription>[]
}

const DefinitionListItem = ({ children }: DefinitionListItemProps) => {
  return (
    <Box flexDirection="column" gap="xs4">
      {children}
    </Box>
  )
}

DefinitionListItem.DT = DefinitionTitle
DefinitionListItem.DD = DefinitionDescription

export { DefinitionListItem }
