import { Box } from "@atoms/Box"
import { ReactElement } from "react"
import { DefinitionListItem } from "./DefinitionListItem"

export interface DefinitionListProps {
  children: ReactElement<typeof DefinitionListItem> | ReactElement<typeof DefinitionListItem>[]
}

const DefinitionList = ({ children }: DefinitionListProps) => {
  return (
    <Box justifyContent="flex-start" gap="xs" $mt="xs2">
      {children}
    </Box>
  )
}

DefinitionList.Item = DefinitionListItem

export { DefinitionList }
