import {
  ExitFormEnum,
  ExitTermsEnum,
  ResignationStatus,
  Steps,
} from "@customTypes/resignation"

export default {
  translations: {
    page: {
      home: {
        title: "Desligamento",
        buttons: {
          refreshKanban: "Atualizar kanban",
          upsertEmployee: "Selecionar pessoa",
        },
        kanban: {
          cardsCountDescription:
            "<display>{{cardsCount}}</display> <description>pessoa(s) na etapa</description>",
          requestDateLabel: "Data da solicitação:",
        },
      },
      interview: {
        organizationTitle: "Organização da entrevista",
        organizationDescription:
          "Você pode optar pela entrevista de desligamento, preenchendo os tópicos discutidos, ou pelo envio de uma pesquisa de desligamento com o módulo de engajamento.",
        dateInterviewLabel: "Data da entrevista",
        responsiblesLabel: "Responsáveis pela entrevista",
        responsiblesPlaceholder: "Digite o nome da pessoa",
        responsiblesLoadingText: "Buscando responsáveis...",
        responsiblesNoOptionText:
          "Não foi encontrada nenhuma pessoa com esse nome.",
        responsiblesInvalidSearchText:
          "Digite ao menos 3 letras do nome da pessoa.",
        notesTitle: "Anotações da entrevista",
        notesDescription:
          "Anexe um arquivo de texto ou digite diretamente pela plataforma.",
        notesLabel: "Anotações da entrevista",
        attachmentLabel: "Adicionar anotações da entrevista",
        attachmentPlaceholder: "Anotações da entrevista",
        reasonLabel: "Justificativa do desligamento",
        reasonOptions: {
          compensation: "Remuneração",
          benefits: "Benefícios",
          culturalFit: "Identificação com a cultura",
          lackOfGrowthOpportunity: "Falta de perspectiva de crescimento",
          careerChange: "Mudança de carreira",
          misalignedActivities: "Atividades desalinhadas com a contratação",
          lackOfManagementSupport: "Falta de acompanhamento da gestão",
          managementChange: "Mudança da gestão",
          lackOfRecognition: "Falta de reconhecimento",
          areaChange: "Mudança de área",
          other: "Outro",
          behavior: "Comportamento",
          performance: "Performance",
          disciplinaryAction: "Ação Disciplinar (Justa Causa)",
          lackOfCulturalFit: "Falta de identificação com a cultura",
          lackOfAdaptation: "Falta de adaptação",
          strategyOrStructureChange: "Mudança de Estratégia/Estrutura",
          internshipCompletion: "Efetivação (em caso de estágio)",
        },
      },
      resignationStep: {
        [Steps.InitialInfo]: {
          title: "Em espera",
          subtitle:
            "Acesse os dados do colaborador e inicie o processo de desligamento de forma fácil e rápida.",
          stepDetailsSection: {
            title: "Dados do desligamento",
            description:
              "Preencha os campos abaixo de acordo com o tipo de desligamento.",
            fields: {
              requestedBy: {
                label: "Solicitado por",
              },
              exitForm: {
                label: "Tipo de desligamento",
              },
              exitTerms: {
                label: "Tipo de rescisão contratual",
              },
              email: {
                label: "E-mail pessoal",
              },
              requestDate: {
                label: "Data de pedido de demissão",
              },
              complyWithNoticePeriod: {
                label: "Irá cumprir aviso prévio?",
                yes: "Sim",
                no: "Não",
              },
              noticePeriodDays: {
                label: "Quantidade de dias de aviso prévio",
                description: "{{noticePeriodDays}} dia(s)",
              },
              terminationDate: {
                label: "Último dia de trabalho efetivo",
              },
              motivation: {
                label: "Motivações do desligamento",
              },
              emptyField: {
                notFound: "Não cadastrado",
                notFilled: "Não preenchido",
              },
            },
          },
        },
        [Steps.Evaluation]: {
          title: "Avaliação de desligamento",
          subtitle: "Documente a avaliação da pessoa sobre a sua empresa.",
        },
        [Steps.Instructions]: {
          title: "Instruções de desligamento",
          subtitle: "Envie um e-mail de instruções para a pessoa.",
        },
        [Steps.ExitExam]: {
          title: "Exame demissional",
          subtitle: "Anexe o exame demissional da pessoa.",
        },
        [Steps.Signature]: {
          title: "Assinatura da rescisão",
          subtitle:
            "Faça o envio dos documentos rescisórios e acompanhe o status da assinatura.",
        },
        [Steps.Done]: {
          title: "Concluído",
          subtitle: "Pessoas com o processo de desligamento concluído.",
        },
        [Steps.Archived]: {
          title: "Arquivado",
          subtitle:
            "Pessoas que foram arquivadas no meio do processo de desligamento.",
        },
        navigation: {
          home: "Desligamento",
        },
        body: {
          header: {
            disclaimer: "Fase atual",
            definitionList: {
              status: "Status",
              responsible: "Responsável",
              followers: "Acompanhantes",
            },
          },
        },
        footer: {
          back: "Voltar",
          jumpStep: "Mover para outra etapa",
          nextStep: "Mover para próxima etapa",
        },
        messages: {
          resignationNotFound:
            "Os dados deste desligamento não foram encontrados. Tente novamente mais tarde!",
        },
        card: {
          personDetails: {
            labels: {
              fullName: "Nome completo",
              cpf: "CPF",
              registration: "Matrícula",
              leader: "Líder",
              email: "E-mail",
              phone: "Celular",
              contractType: "Tipo de contrato",
              admissionDate: "Data de admissão",
              communicationDate: "Comunicação de desligamento",
            },
            values: {
              default: "Não preenchido",
            },
          },
        },
      },
      components: {
        personDetails: {
          tab: {
            information: "Pessoa",
            historic: "Histórico",
          },
        },
        detailItem: {
          notFilled: "Não preenchido",
        },
        resignationStatusTag: {
          [ResignationStatus.EvaluationCompleted]: "Concluído",
          [ResignationStatus.EvaluationInProgress]: "Em andamento",
          [ResignationStatus.EvaluationPendingInterview]: "Entrevista pendente",
          [ResignationStatus.ExitExamAwaitingPersonResponse]:
            "Aguardando retorno da pessoa",
          [ResignationStatus.ExitExamCompleted]: "Concluído",
          [ResignationStatus.ExitEmailPendingRequest]:
            "Envio de solicitação pendente",
          [ResignationStatus.ExitExamPendingValidation]: "Validação pendente",
          [ResignationStatus.InstructionsCompleted]: "Concluído",
          [ResignationStatus.InstructionsInProgress]: "Em andamento",
          [ResignationStatus.InstructionsPendingEmail]:
            "Envio de e-mail pendente",
          [ResignationStatus.InitialInfoCompleted]: "Concluído",
          [ResignationStatus.InitialInfoInApproval]: "Em aprovação",
          [ResignationStatus.InitialInfoPendingCommunication]:
            "Comunicação pendente",
          [ResignationStatus.InitialInfoRejected]: "Reprovado",
          [ResignationStatus.SignaturePendingContract]:
            "Envio do contrato pendente",
          [ResignationStatus.SignaturePendingSignature]:
            "{{pendingSignaturesCount}} assinatura(s) pendente(s)",
          [ResignationStatus.SignatureSigned]: "Assinado",
        },
        upsertEmployeeModal: {
          title: "Selecionar pessoa",
          description:
            "Selecione uma pessoa e inclua-a no fluxo de desligamento\nNão se preocupe, elas não serão notificadas sobre isso por enquanto",
          buttons: {
            open: "Selecionar pessoa",
            submit: "Selecionar pessoa",
            cancel: "Cancelar",
          },
          form: {
            employee: {
              label: "Selecione a pessoa",
              invalidSearchText: "Digite ao menos 3 letras do nome da pessoa.",
              noOptionText: "Não foi encontrada nenhuma pessoa com esse nome.",
              loadingText: "Buscando pessoa...",
            },
            responsible: {
              label: "Responsável pelo desligamento",
              invalidSearchText: "Digite ao menos 3 letras do nome da pessoa.",
              noOptionText: "Não foi encontrada nenhuma pessoa com esse nome.",
              loadingText: "Buscando pessoa...",
            },
            followers: {
              label: "Acompanhantes do desligamento",
              invalidSearchText: "Digite ao menos 3 letras do nome da pessoa.",
              noOptionText: "Não foi encontrada nenhuma pessoa com esse nome.",
              loadingText: "Buscando pessoa...",
            },
            exitForm: {
              label: "Tipo de desligamento",
              values: {
                [ExitFormEnum.Voluntary]: "Voluntário",
                [ExitFormEnum.Involuntary]: "Involuntário",
              },
            },
            exitTerms: {
              label: "Tipo de rescisão contratual",
              values: {
                [ExitTermsEnum.WithoutCause]: "Demissão sem justa causa",
                [ExitTermsEnum.ForCause]: "Demissão por justa causa",
                [ExitTermsEnum.MutualAgreement]: "Demissão consensual",
                [ExitTermsEnum.Resignation]: "Pedido de demissão pela pessoa",
              },
            },
            email: "E-mail pessoal",
            requestDate: "Data do pedido de desligamento",
            noticePeriod: "Irá cumprir aviso prévio",
            terminationDate: "Último dia de trabalho efetivo",
            motivation: "Motivações do desligamento",
            errors: {
              required: "Campo obrigatório",
              employeeIsAlreadyAdded:
                "Pessoa indisponível devido a desligamento em andamento ou concluído.",
              invalidResponsible:
                "Pessoa selecionada não pode ser a responsável pelo desligamento.",
              responsibleIsOnResignation:
                "Responsável indisponível devido a desligamento em andamento ou concluído.",
              followerIsOnResignation:
                "Acompanhante indisponível devido a desligamento em andamento ou concluído.",
              checkFields: "Verifique os campos do formulário.",
            },
          },
        },
        upload: {
          loadingTitle: "Carregando o arquivo, por favor, aguarde!",
          errorArchiveSize:
            "Este arquivo ultrapassa o limite máximo. Por favor, faça o upload de um arquivo menor.",
          errorName: "Nome não identificado",
          errorMultipleFiles: "Apenas um arquivo é permitido.",
          errorUpload: "Erro ao fazer o upload do arquivo.",
        },
      },
      types: {
        exitForm: {
          [ExitFormEnum.Involuntary]: "Involuntário",
          [ExitFormEnum.Voluntary]: "Voluntário",
        },
        exitTerms: {
          [ExitTermsEnum.ForCause]: "Demissão por justa causa",
          [ExitTermsEnum.MutualAgreement]: "Demissão consensual",
          [ExitTermsEnum.Resignation]: "Pedido de demissão pela pessoa",
          [ExitTermsEnum.WithoutCause]: "Demissão sem justa causa",
        },
      },
      error: {
        internalServerError:
          "Ocorreu um problema no sistema, por favor tente novamente mais tarde.",
      },
    },
  },
}
