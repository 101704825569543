import { Box } from "@atoms"
import { Icons, LinkButton, PillButton, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { useNavigate } from "react-router-dom"
import * as SC from "./styles"

export interface NavigationHistory {
  label: string
  path?: string
}

export interface NavigationHeaderProps {
  disableGoBack?: boolean
  navigation: NavigationHistory[]
}

export const NavigationHeader = ({ disableGoBack = false, navigation }: NavigationHeaderProps) => {
  const navigate = useNavigate()

  const handleReturn = () => navigate(-1)

  const breadcrumbs = navigation.map(({ path, label }) => {
    return path ? (
      <LinkButton variant="neutral" size="medium" onClick={() => navigate(path, { replace: true })}>
        {label}
      </LinkButton>
    ) : (
      <Typography.Body4 weight={700} color={"neutral.50"}>
        {label}
      </Typography.Body4>
    )
  })

  return (
    <Box gap="xs" $px="xs" $py="xs3">
      <PillButton
        onClick={handleReturn}
        disabled={disableGoBack}
        size="extra-small"
        variant="default"
        icon="IconChevronLeft"
      />
      <SC.FitContentBreadcrumbs
        separator={<Icons name="IconChevronRight" fill="transparent" />}
        breadcrumbs={breadcrumbs}
      />
    </Box>
  )
}
