import { Box } from "@atoms"
import { Card } from "@atoms/Card"
import { ExitFormEnum, ExitTermsEnum, Steps } from "@customTypes/resignation"
import { PillButton, Tag, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import i18n from "@i18n"
import { useTranslation } from "react-i18next"
import { dayjs } from "@utils/dayjs"

export interface StepDetailsSectionProps {
  onEditClick: () => void
  employeeEmail?: string
  exitForm: ExitFormEnum
  exitTerms: ExitTermsEnum
  motivation?: string
  noticePeriod?: boolean
  requestedBy: string
  requestDate: Date
  terminationDate?: Date
}

export const StepDetailsSection = ({
  onEditClick,
  employeeEmail,
  exitForm,
  exitTerms,
  terminationDate,
  motivation,
  noticePeriod,
  requestedBy,
  requestDate,
}: StepDetailsSectionProps) => {
  const [t] = useTranslation("translations", {
    keyPrefix: `page.resignationStep.${Steps.InitialInfo}.stepDetailsSection`,
  })

  const exitFormDescription = i18n.t(`page.types.exitForm.${exitForm}`)
  const exitTermsDescription = i18n.t(`page.types.exitTerms.${exitTerms}`)

  const noticePeriodDays = noticePeriod
    ? dayjs(terminationDate).diff(dayjs(requestDate), "day")
    : undefined

  return (
    <>
      <Card $width={"100%"}>
        <Box gap="xs" flexDirection="column" $width={"100%"}>
          <Box justifyContent="space-between" gap="xs">
            <div>
              <Typography.Headline8 color="neutral.30">
                {t("title")}
              </Typography.Headline8>
              <Typography.Body4 weight={400} color="neutral.50">
                {t("description")}
              </Typography.Body4>
            </div>
            {onEditClick && (
              <PillButton
                size="medium"
                variant="default"
                onClick={onEditClick}
                icon="IconPencil"
              />
            )}
          </Box>
          <div>
            <Typography.Caption weight={600} color="neutral.50">
              {t("fields.requestedBy.label")}
            </Typography.Caption>
            <Tag variant="primary">{requestedBy}</Tag>
          </div>
          <div>
            <Typography.Caption weight={600} color="neutral.50">
              {t("fields.exitForm.label")}
            </Typography.Caption>
            <Typography.Body3 weight={600} color="neutral.30">
              {exitFormDescription}
            </Typography.Body3>
          </div>
          <div>
            <Typography.Caption weight={600} color="neutral.50">
              {t("fields.exitTerms.label")}
            </Typography.Caption>
            <Typography.Body3 weight={600} color="neutral.30">
              {exitTermsDescription}
            </Typography.Body3>
          </div>
          <div>
            <Typography.Caption weight={600} color="neutral.50">
              {t("fields.email.label")}
            </Typography.Caption>
            <Typography.Body3 weight={600} color="neutral.30">
              {employeeEmail ?? t("fields.emptyField.notFilled")}
            </Typography.Body3>
          </div>
          <div>
            <Typography.Caption weight={600} color="neutral.50">
              {t("fields.requestDate.label")}
            </Typography.Caption>
            <Typography.Body3 weight={600} color="neutral.30">
              {dayjs(requestDate).format("DD/MM/YYYY")}
            </Typography.Body3>
          </div>
          <div>
            <Typography.Caption weight={600} color="neutral.50">
              {t("fields.complyWithNoticePeriod.label")}
            </Typography.Caption>
            <Typography.Body3 weight={600} color="neutral.30">
              {noticePeriod
                ? t("fields.complyWithNoticePeriod.yes")
                : t("fields.complyWithNoticePeriod.no")}
            </Typography.Body3>
          </div>
          <div>
            <Typography.Caption weight={600} color="neutral.50">
              {t("fields.noticePeriodDays.label")}
            </Typography.Caption>
            <Typography.Body3 weight={600} color="neutral.30">
              {t("fields.noticePeriodDays.description", {
                noticePeriodDays: noticePeriodDays ?? 0,
              })}
            </Typography.Body3>
          </div>
          <div>
            <Typography.Caption weight={600} color="neutral.50">
              {t("fields.terminationDate.label")}
            </Typography.Caption>
            <Typography.Body3 weight={600} color="neutral.30">
              {dayjs(terminationDate).format("DD/MM/YYYY")}
            </Typography.Body3>
          </div>
          <div>
            <Typography.Caption weight={600} color="neutral.50">
              {t("fields.motivation.label")}
            </Typography.Caption>
            <Typography.Body3 weight={600} color="neutral.30">
              {motivation ?? t("fields.emptyField.notFilled")}
            </Typography.Body3>
          </div>
        </Box>
      </Card>
    </>
  )
}
