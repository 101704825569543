export enum ExitFormEnum {
  Voluntary = "voluntary",
  Involuntary = "involuntary",
}

export enum ExitTermsEnum {
  WithoutCause = "termination-without-cause",
  ForCause = "termination-for-cause",
  MutualAgreement = "mutual-termination",
  Resignation = "employee-resignation",
}

export enum Steps {
  InitialInfo = "initial_info",
  Evaluation = "evaluation",
  Instructions = "instructions",
  ExitExam = "exit_exam",
  Signature = "signature",
  Done = "done",
  Archived = "archived",
}

export enum ResignationStatus {
  Created = "created",

  InitialInfoInApproval = "initial-info-in-approval",
  InitialInfoPendingCommunication = "initial-info-pending-communication",
  InitialInfoRejected = "initial-info-rejected",
  InitialInfoCompleted = "initial-info-completed",

  EvaluationPendingInterview = "evaluation-pending-interview",
  EvaluationInProgress = "evaluation-in-progress",
  EvaluationCompleted = "evaluation-completed",

  InstructionsPendingEmail = "instructions-pending-email",
  InstructionsInProgress = "instructions-in-progress",
  InstructionsCompleted = "instructions-completed",

  ExitEmailPendingRequest = "exit-exam-pending-request",
  ExitExamAwaitingPersonResponse = "exit-exam-awaiting-person-response",
  ExitExamPendingValidation = "exit-exam-pending-validation",
  ExitExamCompleted = "exit-exam-completed",

  SignaturePendingContract = "signature-pending-contract",
  SignaturePendingSignature = "signature-pending-signature",
  SignatureSigned = "signature-signed",
}

export enum ResignationColumnsOrder {
  InitialInfo = 1,
  Evaluation = 2,
  Instructions = 3,
  ExitExam = 4,
  Signature = 5,
  Completed = 6,
  Archived = 7,
}

export interface Card {
  position: number
}

export enum InterviewStatus {
  NOT_STARTED = "not_started",
  IN_PROGRESS = "in_progress",
  DONE = "done",
}

export type File = {
  path: string
  value?: string
}

export enum VoluntaryTerminationReason {
  Compensation = "Compensation",
  Benefits = "Benefits",
  CulturalFit = "CulturalFit",
  LackOfGrowthOpportunity = "LackOfGrowthOpportunity",
  CareerChange = "CareerChange",
  MisalignedActivities = "MisalignedActivities",
  LackOfManagementSupport = "LackOfManagementSupport",
  ManagementChange = "ManagementChange",
  LackOfRecognition = "LackOfRecognition",
  AreaChange = "AreaChange",
  Other = "Other",
}

export enum InvoluntaryTerminationReason {
  Behavior = "Behavior",
  Performance = "Performance",
  DisciplinaryAction = "DisciplinaryAction",
  LackOfCulturalFit = "LackOfCulturalFit",
  LackOfAdaptation = "LackOfAdaptation",
  StrategyOrStructureChange = "StrategyOrStructureChange",
  InternshipCompletion = "InternshipCompletion",
}

export type Interview = {
  status?: InterviewStatus
  date?: Date
  responsiblesIds?: string[]
  notes?: string
  attachment?: File
  reason?: VoluntaryTerminationReason | InvoluntaryTerminationReason
}

export interface Resignation {
  id: string
  step: Steps
  employeeId: string
  companyId: string
  responsibleId: string
  followers: string[]
  exitForm: ExitFormEnum
  email?: string
  requestDate: Date
  status: ResignationStatus
  exitTerms: ExitTermsEnum
  noticePeriod?: boolean
  terminationDate: Date
  motivation?: string
  createdAt?: Date
  updatedAt?: Date
  card: Card
  interview?: Interview
}

type FollowerEmployee = {
  id: string
  name: string
  profilePicture?: string
}

export type GetResignationResponse = Omit<Resignation, "followers"> & {
  employeeName: string
  employeePicture?: string
  responsibleName: string
  responsiblePicture?: string
  followers: FollowerEmployee[]
}
