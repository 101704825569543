import {
  IconTypes,
  PillButton,
  Tooltip,
} from "@flash-tecnologia/hros-web-ui-v2"

type ActionProps = {
  icon: IconTypes
  onClick: () => void
  title: string
}

export const Action = ({ icon, onClick, title }: ActionProps) => {
  return (
    <Tooltip title={title} placement="bottom" arrow>
      <span>
        <PillButton
          icon={icon}
          variant="default"
          size="small"
          onClick={onClick}
        />
      </span>
    </Tooltip>
  )
}
