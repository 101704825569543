import { Box } from "@atoms"
import { File, Steps } from "@customTypes/resignation"
import { Divider, Icons, TextField } from "@flash-tecnologia/hros-web-ui-v2"
import { Upload } from "@molecules"
import { forwardRef } from "react"
import { useTranslation } from "react-i18next"
import { useTheme } from "styled-components"
import { EmailInstructionsFormRef } from "../../hooks/useFormalizationStep"
import { useEmailInstructionsForm } from "../../hooks/useEmailInstructionsForm"

interface EmailInstructionsSectionProps {
  attachmentUploadError?: boolean
  initialAttachment?: File
  initialEmail?: string
  initialSendingLetterDeadline?: Date
  onAttachmentUpload: (file: File) => Promise<void>
  onAttachmentDelete: () => void
}

export const EmailInstructionsSection = forwardRef<
  EmailInstructionsFormRef,
  EmailInstructionsSectionProps
>(
  (
    {
      attachmentUploadError = false,
      initialAttachment,
      initialEmail,
      initialSendingLetterDeadline,
      onAttachmentUpload,
      onAttachmentDelete,
    },
    ref,
  ) => {
    const [t] = useTranslation("translations", {
      keyPrefix: `page.resignationStep.${Steps.Formalization}.emailInstructionsSection`,
    })

    const {
      attachment,
      email,
      emailError,
      onEmailBlur,
      onEmailChange,
      onAttachmentDownload,
    } = useEmailInstructionsForm({
      emailInstructionsFormRef: ref,
      initialAttachment,
      initialEmail,
      initialSendingLetterDeadline,
    })

    const theme = useTheme()

    return (
      <Box $width="100%" alignItems="center" gap="xs1" flexDirection="column">
        <Upload.Root
          error={attachmentUploadError}
          folder="formalization"
          helperText={t("uploader.helperText")}
          label={t("uploader.label")}
          placeholder={t("uploader.placeholder")}
          value={attachment}
          onUpload={onAttachmentUpload}
          accept={["pdf"]}
        >
          <Upload.Action icon={"IconDownload"} onClick={onAttachmentDownload} />
          <Upload.Action icon={"IconTrash"} onClick={onAttachmentDelete} />
        </Upload.Root>
        <Divider orientation="horizontal" />
        <Box $width="100%" flexDirection="column">
          <TextField
            error={!!emailError}
            helperText={emailError}
            label={t("form.fields.email.label")}
            onBlur={onEmailBlur}
            required
            fullWidth
            InputProps={{
              endAdornment: (
                <Icons name="IconMail" color={theme.colors.neutral[50]} />
              ),
            }}
            onChange={(event) => onEmailChange(event.target.value)}
            value={email}
          />
        </Box>
      </Box>
    )
  },
)
