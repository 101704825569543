import { Box, Clipboard } from "@atoms"
import {
  ColorToken,
  Icons,
  IconTypes,
  Skeleton,
} from "@flash-tecnologia/hros-web-ui-v2"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useTheme } from "styled-components"
import { EllipsedText, Wrapper } from "./styles"

export enum DetailItemTypes {
  Default = "DEFAULT",
  Error = "ERROR",
}

export type DetailItemProps = {
  value?: string
  enableCopy?: boolean
  label: string
  icon: IconTypes
  tagValue?: string
  type?: DetailItemTypes
  loading?: boolean
}

export const DetailItem = ({
  icon,
  label,
  value,
  enableCopy = false,
  type = DetailItemTypes.Default,
  loading = false,
}: DetailItemProps) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const componentsColorsPerType: Record<
    DetailItemTypes,
    { iconColor: string; labelColor: ColorToken; valueColor: ColorToken }
  > = useMemo(() => {
    return {
      [DetailItemTypes.Default]: {
        iconColor: theme.colors.neutral[30],
        labelColor: "neutral.50",
        valueColor: "neutral.40",
      },
      [DetailItemTypes.Error]: {
        iconColor: theme.colors.feedback.error[40],
        labelColor: "feedback.error.40",
        valueColor: "feedback.error.40",
      },
    }
  }, [theme])

  if (loading) {
    return <Skeleton variant="rectangular" width={"100%"} height={"50px"} />
  }

  return (
    <Box alignItems="center" gap="xs2">
      <Box $width={"max-content"}>
        <Icons
          name={icon}
          fill="transparent"
          color={componentsColorsPerType[type].iconColor}
        />
      </Box>

      <Wrapper>
        <EllipsedText
          variant="caption"
          weight={700}
          color={componentsColorsPerType[type].labelColor}
        >
          {label}
        </EllipsedText>

        <Box alignItems="center" $width={"100%"}>
          <EllipsedText
            variant="body3"
            weight={600}
            color={componentsColorsPerType[type].valueColor}
          >
            {value || t("page.components.detailItem.notFilled")}
          </EllipsedText>

          {!!enableCopy && !!value && <Clipboard value={value} />}
        </Box>
      </Wrapper>
    </Box>
  )
}
