import { trpc } from "@api/client"
import { Steps } from "@customTypes/resignation"
import i18n from "@i18n"
import dispatchToast from "@utils/dispatchToast"
import { useState } from "react"
import { useTranslation } from "react-i18next"

export function useResetFormalization({
  resignationId,
}: {
  resignationId: string
}) {
  const [t] = useTranslation("translations", {
    keyPrefix: `page.resignationStep.${Steps.Formalization}`,
  })

  const utils = trpc.useUtils()

  const [resetModalOpen, setResetModalOpen] = useState(false)

  const { mutateAsync: configureDefaultFormalization, isLoading } =
    trpc.resignation.configureDefaultFormalization.useMutation({
      onError: (error) => {
        dispatchToast({
          type: "error",
          content:
            error.data?.userFriendlyError?.message ??
            i18n.t("error.internalServerError"),
        })
      },
      onSuccess: () => {
        utils.resignation.getResignation.invalidate()
        setResetModalOpen(false)
        dispatchToast({
          type: "success",
          content: t("reset.success"),
        })
      },
    })

  const handleResetClick = () => {
    setResetModalOpen(true)
  }

  const handleSubmit = () =>
    configureDefaultFormalization({
      resignationId,
    })

  const handleModalVisiblityChange = (open: boolean) => {
    !open && !isLoading && setResetModalOpen(false)
  }

  const handleCancel = () => !isLoading && setResetModalOpen(false)

  return {
    isSubmitLoading: isLoading,
    onCancel: handleCancel,
    onModalVisibilityChange: handleModalVisiblityChange,
    onResetClick: handleResetClick,
    onSubmit: handleSubmit,
    resetModalOpen,
  }
}
