import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import * as SC from "./styles"

export interface TitleProps {
  children: string
}

export const Title = ({ children }: TitleProps) => {
  return (
    <SC.TitleWrapper>
      <Typography.Headline6 weight={700} color={"neutral.20"}>
        {children}
      </Typography.Headline6>
    </SC.TitleWrapper>
  )
}
