import { Box, Card } from "@atoms"
import {
  ExitFormEnum,
  Formalization,
  FormalizationStatus,
  Steps,
} from "@customTypes/resignation"
import { Modal } from "@flash-tecnologia/flamingo-ui-modal"
import {
  Button,
  Divider,
  Icons,
  PillButton,
} from "@flash-tecnologia/hros-web-ui-v2"
import { useTranslation } from "react-i18next"
import { EmailInstructionsForm } from "../../hooks/useEmailInstructionsForm"
import { useFormalizationStep } from "../../hooks/useFormalizationStep"
import { useResetFormalization } from "../../hooks/useResetFormalization"
import { EmailInstructionsSection } from "../EmailInstructionsSection"
import { LetterForm, LetterSection } from "../LetterSection"
import { createFormContext } from "@utils/createFormContext"

interface FormalizationStepProps {
  initialResignation: {
    exitForm: ExitFormEnum
    formalization: Formalization
    id: string
    email?: string
  }
}

export const {
  FormProvider: LetterFormProvider,
  useFormContext: useLetterFormContext,
} = createFormContext<LetterForm>()

export const {
  FormProvider: EmailInstructionsFormProvider,
  useFormContext: useEmailInstructionsFormContext,
} = createFormContext<EmailInstructionsForm>()

export const FormalizationStep = ({
  initialResignation,
}: FormalizationStepProps) => {
  const [t] = useTranslation("translations", {
    keyPrefix: `page.resignationStep.${Steps.Formalization}`,
  })

  const {
    isSubmitLoading,
    onCancel,
    onModalVisibilityChange,
    onResetClick,
    onSubmit,
    resetModalOpen,
  } = useResetFormalization({ resignationId: initialResignation.id })

  return (
    <Box $width="100%" flexDirection="column" gap="xs2">
      <Box $width="100%" justifyContent="flex-end">
        <PillButton
          disabled={
            initialResignation.formalization.status ===
            FormalizationStatus.PendingEmail
          }
          icon="IconRotate2"
          iconPosition="left"
          label={t("reset.button")}
          onClick={onResetClick}
          size="small"
          variant="pink"
        />
        <Modal.Root
          open={resetModalOpen}
          size="small"
          onOpenChange={onModalVisibilityChange}
        >
          <Modal.Content.Root disableCloseOnOverlayClick={isSubmitLoading}>
            <Modal.Content.Highlight size="default" type="announcement">
              {t("reset.modal.highlight")}
            </Modal.Content.Highlight>
            <Modal.Content.Title>{t("reset.modal.title")}</Modal.Content.Title>
            <Modal.Content.Description>
              {t("reset.modal.description")}
            </Modal.Content.Description>
            <Modal.Content.Footer>
              <Modal.Content.Footer.CancelButton onClick={onCancel}>
                {t("reset.modal.footer.cancel")}
              </Modal.Content.Footer.CancelButton>
              <Modal.Content.Footer.PrimaryButton
                loading={isSubmitLoading}
                onClick={onSubmit}
              >
                {t("reset.modal.footer.submit")}
                <Icons name="IconCheck" />
              </Modal.Content.Footer.PrimaryButton>
            </Modal.Content.Footer>
          </Modal.Content.Root>
        </Modal.Root>
      </Box>
      <Card $width={"100%"}>
        <Box flexDirection="column" gap="xs" $width={"100%"}>
          <EmailInstructionsFormProvider>
            <LetterFormProvider>
              <FormsOrchestrator initialResignation={initialResignation} />
            </LetterFormProvider>
          </EmailInstructionsFormProvider>
        </Box>
      </Card>
    </Box>
  )
}

const FormsOrchestrator = ({ initialResignation }: FormalizationStepProps) => {
  const {
    attachmentUploadError,
    displaySubmitButton,
    emailInstructionsFormRef,
    isSubmitButtonLoading,
    letterSectionFormRef,
    submitButtonText,
    onAttachmentDelete,
    onAttachmentUpload,
    onSubmit,
  } = useFormalizationStep({
    initialResignation,
  })

  return (
    <>
      {initialResignation.exitForm === ExitFormEnum.Voluntary && (
        <>
          <LetterSection
            ref={letterSectionFormRef}
            initialShouldRequestLetter={
              initialResignation.formalization?.shouldRequestLetter
            }
            initialSendingLetterDeadline={
              initialResignation.formalization?.externalLink?.dueDate
            }
          />
          <Divider orientation="horizontal" />
        </>
      )}
      <EmailInstructionsSection
        ref={emailInstructionsFormRef}
        attachmentUploadError={attachmentUploadError}
        onAttachmentUpload={onAttachmentUpload}
        onAttachmentDelete={onAttachmentDelete}
        initialAttachment={initialResignation.formalization.attachment}
        initialSendingLetterDeadline={
          initialResignation.formalization.externalLink?.dueDate
        }
        initialEmail={
          initialResignation.email || initialResignation.formalization?.email
        }
      />
      {displaySubmitButton && (
        <Button
          loading={isSubmitButtonLoading}
          variant="primary"
          minWidth="100%"
          onClick={onSubmit}
        >
          {submitButtonText}
          <Icons name="IconMail" />
        </Button>
      )}
    </>
  )
}
