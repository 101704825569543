import { ReactNode } from "react"
import styled from "styled-components"

export interface ContentWrapperProps {
  children: ReactNode
}

export const PageBodyWrapper = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacings.m};

  padding-top: ${({ theme }) => theme.spacings.m};
  grid-template-columns: 1fr 6.5fr 3.5fr 1fr;
`
