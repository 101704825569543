import { ReactElement } from "react"
import { DefinitionList } from "../DefinitionList"
import { Disclaimer } from "../Disclaimer"
import { Subtitle } from "../Subtitle"
import { Title } from "../Title"

export interface RootProps {
  children: ReactElement<typeof Disclaimer | typeof Title | typeof Subtitle | typeof DefinitionList>[]
}

export const Root = ({ children }: RootProps) => {
  return <div>{children}</div>
}
