import { trpc } from "@api/client"
import { Box } from "@atoms"
import { Steps } from "@customTypes/resignation"
import i18n from "@i18n"
import { mapResignationStatusToTagProps } from "@pages/Home/ResignationKanban/utils/mapResignationStatusToTagProps"
import { NavigationHeaderProps } from "@templates/StepPage/NavigationHeader"
import dispatchToast from "@utils/dispatchToast"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { Evaluation } from "../components/steps/Evaluation"
import { FormalizationStep } from "../components/steps/formalization/components/FormalizationStep"
import { StepDetailsSection } from "../components/steps/initialInfo/StepDetailsSection"

export function useResignationStepPage() {
  const [t] = useTranslation("translations", {
    keyPrefix: "page.resignationStep",
  })
  const navigate = useNavigate()

  const { resignationId = "" } = useParams()

  const {
    isLoading: isGetResignationLoading,
    isRefetching: isGetResignationRefecthing,
    data: resignation,
    error: getResignationError,
  } = trpc.resignation.getResignation.useQuery({ resignationId })

  useEffect(() => {
    if (getResignationError) {
      dispatchToast({
        content:
          getResignationError.data?.userFriendlyError?.message ??
          i18n.t("error.internalServerError"),
        type: "error",
      })
    }
  }, [getResignationError])

  const PageLeftContent: JSX.Element | undefined =
    resignation &&
    {
      [Steps.Initiated]: (
        <Box $mb="m" $width={"100%"}>
          <StepDetailsSection
            employeeEmail={resignation.email}
            exitForm={resignation.exitForm}
            exitTerms={resignation.exitTerms}
            motivation={resignation.motivation}
            noticePeriod={resignation.noticePeriod}
            onEditClick={() => {}}
            requestDate={resignation.requestDate}
            requestedBy={resignation.responsibleName}
            terminationDate={resignation.terminationDate}
          />
        </Box>
      ),
      [Steps.Evaluation]: (
        <Box $mb="m" $width={"100%"}>
          <Evaluation
            resignationId={resignationId}
            exitForm={resignation.exitForm}
            initialEvaluation={resignation.evaluation}
          />
        </Box>
      ),
      [Steps.Formalization]: (
        <Box flexDirection="column" $mb="m" $width={"100%"}>
          {resignation.formalization && (
            <FormalizationStep
              initialResignation={{
                exitForm: resignation.exitForm,
                formalization: resignation.formalization,
                id: resignationId,
                email: resignation.email,
              }}
            />
          )}
        </Box>
      ),
      [Steps.ExitExam]: undefined,
      [Steps.Signature]: undefined,
      [Steps.Done]: undefined,
      [Steps.Archived]: undefined,
    }[resignation?.step]

  if (!resignationId) {
    navigate("/resignation", { replace: true })
    return {}
  }

  const navigationHeaderProps: NavigationHeaderProps = {
    navigation: [
      {
        label: t("navigation.home"),
        path: "/resignation",
      },
      {
        label: t(`${resignation?.step}.title`),
      },
    ],
  }

  const status =
    (resignation &&
      (resignation as Partial<Record<Steps, { status?: string }>>)[
        resignation.step
      ]?.status) ??
    ""

  const tagProps = resignation && mapResignationStatusToTagProps(status)

  return {
    isGetResignationLoading:
      isGetResignationLoading || isGetResignationRefecthing,
    navigationHeaderProps,
    PageLeftContent,
    resignation: {
      id: resignation?.id ?? resignationId,
      employeeId: resignation?.employeeId,
      responsibleName: resignation?.responsibleName,
      status: status,
      step: resignation?.step,
      followers: resignation?.followers,
    },
    tagProps,
  }
}
