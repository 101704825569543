import { Box } from "@atoms"
import { ResignationKanban } from "./ResignationKanban"
import {
  Button,
  PillButton,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import { useTheme } from "styled-components"
import { useTranslation } from "react-i18next"
import { trpc } from "@api/client"
import { useState } from "react"
import { UpsertEmployeeModal } from "./UpsertEmployeeModal"

export const Home = () => {
  const theme = useTheme()

  const [t] = useTranslation("translations", { keyPrefix: "page.home" })

  const [upsertEmployeeModalVisible, setUpsertEmployeeModalVisible] =
    useState(false)

  const utils = trpc.useUtils()

  const refreshBoard = () => {
    utils.flow.getFlow.invalidate()
  }

  return (
    <Box $px="xs" $py="m" flexDirection="column" gap="xs">
      <Box
        $px="xs2"
        alignItems="center"
        justifyContent="space-between"
        $width={"100%"}
      >
        <Typography
          variant="headline6"
          variantColor={theme.colors.neutral[20]}
          weight={700}
        >
          {t("title")}
        </Typography>

        <Box
          flexDirection="row"
          gap="xs2"
          alignItems="center"
          justifyContent="center"
        >
          <div>
            <PillButton
              onClick={() => refreshBoard()}
              label={t("buttons.refreshKanban")}
              icon="IconRefresh"
              iconPosition="right"
              variant="default"
              size="small"
            />
          </div>
          <div>
            <PillButton
              size="small"
              variant="default"
              icon="IconSettings"
              onClick={() => {}}
            />
          </div>
          <Button
            variant="primary"
            size="large"
            onClick={() => setUpsertEmployeeModalVisible(true)}
          >
            {t("buttons.upsertEmployee")}
          </Button>
        </Box>
      </Box>
      <ResignationKanban />
      <UpsertEmployeeModal
        visible={upsertEmployeeModalVisible}
        onClose={() => setUpsertEmployeeModalVisible(false)}
      />
    </Box>
  )
}
