import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"

export const Container = styled.div`
  display: grid;
  grid-template-columns: 45px 1fr;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs3};
  padding: ${({ theme }) => theme.spacings.s} ${({ theme }) => theme.spacings.m};
  border: 1px solid ${({ theme }) => theme.colors.feedback.error[50]};
  border-radius: ${({ theme }) => theme.borders.radius.m};
  overflow: scroll;
`

export const EllipsisTypography = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
