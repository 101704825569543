import { Box } from "@atoms"
import { Skeleton, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import i18n from "@i18n"
import PersonDetails from "@organisms/PersonDetails"
import { usePersonDetails } from "@pages/ResignationStep/hooks/usePersonDetails"
import { useState } from "react"
import { TabButton, TabContainer } from "./styles"
import { useTranslation } from "react-i18next"
import { copyToClipboard } from "@utils"
import dispatchToast from "@utils/dispatchToast"
import { generatePath } from "react-router-dom"
import { externalRoutes } from "src/routes/supportedExternalRoutes"
import { trpc } from "@api/client"
import React from "react"
import { Steps } from "@customTypes/resignation"
import { ArchiveModal } from "@organisms"

enum TabEnum {
  Information = "information",
  Historic = "historic",
}

const tabNames: Record<TabEnum, string> = {
  [TabEnum.Information]: i18n.t(
    "page.components.personDetails.tab.information",
  ),
  [TabEnum.Historic]: i18n.t("page.components.personDetails.tab.historic"),
}

interface PersonProps {
  communicationDate?: Date
  resignationId: string
  employeeId: string
  currentStep: Steps
}

export const Historic = () => {
  return (
    <Box
      $width="100%"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap="m"
    >
      <Typography.Body1 color="neutral.50">To be implemented</Typography.Body1>
    </Box>
  )
}

const Person = ({
  communicationDate,
  employeeId,
  resignationId,
  currentStep,
}: PersonProps) => {
  const [archiveModal, setArchiveModal] = React.useState<{
    open: boolean
    callback?: () => Promise<void>
  }>({ open: false })

  const { isLoading, profilePicture, personDetails } = usePersonDetails({
    communicationDate,
    employeeId,
  })

  const utils = trpc.useUtils()
  const [t] = useTranslation("translations", {
    keyPrefix: "page.resignationStep.card.personDetails",
  })

  const { mutateAsync: changeStep, error: changeStepError } =
    trpc.flow.changeStep.useMutation()

  React.useEffect(() => {
    if (!!changeStepError) {
      dispatchToast({
        content:
          changeStepError.data?.userFriendlyError?.message ??
          i18n.t("error.internalServerError"),
        type: "error",
      })
    }
  }, [changeStepError])

  if (isLoading) {
    return (
      <Box $width={"100%"} flexDirection="column">
        <Box
          $width="100%"
          gap="xs2"
          flexDirection="column"
          alignItems="center"
          $px="m"
          $mb="m"
        >
          <Skeleton variant="circular" width={"150px"} height={"150px"} />
          <Skeleton variant="rectangular" width={"60%"} height={"30px"} />
          <Skeleton variant="rectangular" width={"30%"} height={"40px"} />
        </Box>
        <Box flexDirection="column" gap="xs">
          <Skeleton variant="rectangular" width={"50%"} height={"50px"} />
          <Skeleton variant="rectangular" width={"50%"} height={"50px"} />
          <Skeleton variant="rectangular" width={"50%"} height={"50px"} />
          <Skeleton variant="rectangular" width={"50%"} height={"50px"} />
          <Skeleton variant="rectangular" width={"50%"} height={"50px"} />
        </Box>
      </Box>
    )
  }

  const handleCopyToClipboard = () => {
    const url = window.location.href
    copyToClipboard(url)
      .then(() => {
        dispatchToast({
          content: t("pillButtons.copyClipboardSuccess"),
          type: "success",
        })
      })
      .catch(() => {
        dispatchToast({
          content: i18n.t("error.internalServerError"),
          type: "error",
        })
      })
  }

  const handleArchive = async () => {
    if (currentStep === Steps.Archived) {
      dispatchToast({
        content: t("pillButtons.alreadyArchived"),
        type: "warning",
      })
      return
    }

    setArchiveModal({
      open: true,
      callback: async () => {
        await changeStep({
          resignationId: resignationId,
          step: Steps.Archived,
          position: 0,
        })
        dispatchToast({
          content: t("pillButtons.archiveSuccess"),
          type: "success",
        })
        utils.resignation.getResignation.refetch({
          resignationId: resignationId,
        })
      },
    })
  }

  const handleCloseArchiveModal = () => {
    setArchiveModal({
      open: false,
    })
  }

  const handleEdit = () => {
    const path = generatePath(externalRoutes.employeesProfile.path, {
      employeeId: employeeId,
    })
    window.open(path, "_blank")
  }

  return (
    <>
      <PersonDetails.Root>
        <PersonDetails.Picture
          name={personDetails.fullName.value ?? ""}
          source={profilePicture ?? ""}
        />
        <PersonDetails.Name name={personDetails.fullName.value ?? ""} />
        <PersonDetails.ActionsWrapper>
          <PersonDetails.Action
            title={t("pillButtons.edit")}
            icon="IconPencil"
            onClick={handleEdit}
          />
          <PersonDetails.Action
            title={t("pillButtons.archive")}
            icon="IconArchive"
            onClick={handleArchive}
          />
          <PersonDetails.Action
            title={t("pillButtons.share")}
            icon="IconShare"
            onClick={handleCopyToClipboard}
          />
        </PersonDetails.ActionsWrapper>
        <PersonDetails.DetailsWrapper>
          {Object.entries(personDetails).map(
            ([key, { icon, label, enableCopy, type, value }]) => (
              <PersonDetails.Detail
                enableCopy={enableCopy}
                key={key}
                icon={icon}
                label={label}
                type={type}
                value={value}
              />
            ),
          )}
        </PersonDetails.DetailsWrapper>
      </PersonDetails.Root>

      {archiveModal.open && (
        <ArchiveModal
          visible={archiveModal.open}
          onClose={handleCloseArchiveModal}
          onConfirm={archiveModal.callback}
        />
      )}
    </>
  )
}

interface BodyRightContentProps {
  resignationId: string
  communicationDate?: Date
  employeeId: string
  currentStep: Steps
}

export const BodyRightContent = ({
  communicationDate,
  employeeId,
  resignationId,
  currentStep,
}: BodyRightContentProps) => {
  const [tab, setTab] = useState<TabEnum>(TabEnum.Information)

  return (
    <Box
      $width="100%"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap="m"
      $mb="m"
    >
      <TabContainer>
        {Object.values(TabEnum).map((tabValue) => (
          <TabButton
            key={tabValue}
            active={tab === tabValue}
            onClick={() => setTab(tabValue)}
          >
            {tabNames[tabValue]}
          </TabButton>
        ))}
      </TabContainer>

      {tab === TabEnum.Information && (
        <Person
          currentStep={currentStep}
          resignationId={resignationId}
          communicationDate={communicationDate}
          employeeId={employeeId}
        />
      )}
      {tab === TabEnum.Historic && <Historic />}
    </Box>
  )
}
