import { Box } from "@atoms"
import { ReactElement } from "react"
import { Body } from "./Body"
import { Footer } from "./Footer"
import { NavigationHeader } from "./NavigationHeader"

interface StepPageTemplateRoot {
  children: ReactElement<typeof NavigationHeader | typeof Body | typeof Footer>[]
}

const Root = ({ children }: StepPageTemplateRoot) => {
  return (
    <Box flexDirection="column" $height={"100%"}>
      {children}
    </Box>
  )
}

export const StepPage = {
  Root,
  NavigationHeader,
  Body,
  Footer,
}
