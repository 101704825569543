import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import styled, { css } from "styled-components"

export const Container = styled.div`
  display: grid;
  grid-template-columns: 45px 1fr max-content;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs3};
  padding: ${({ theme }) => theme.spacings.s} ${({ theme }) => theme.spacings.m};
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: ${({ theme }) => theme.borders.radius.m};
  overflow: scroll;
`

export const PreviewImage = styled.div<{ url?: string }>`
  width: 45px;
  height: 45px;
  background-color: #ccc;
  background-repeat: no-repeat;
  background-size: cover;
  ${({ url }) =>
    url &&
    css`
      background-image: url(${url});
    `};
`

export const EllipsisTypography = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
