import styled from "styled-components"

export const RadioAccordionWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  width: 100%;
  gap: ${({ theme }) => theme.spacings.xs1};
  align-items: center;
`

export const RadioAccordionContent = styled.div`
  grid-column: 2 / 3;
`
