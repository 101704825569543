import { DefinitionList } from "./DefinitionList"
import { Disclaimer } from "./Disclaimer"
import { Root } from "./Root"
import { Subtitle } from "./Subtitle"
import { Title } from "./Title"

export const StepPageHeader = {
  Root,
  Disclaimer,
  Title,
  Subtitle,
  DefinitionList,
}
