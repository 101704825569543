import { IconTypes, PillButton } from "@flash-tecnologia/hros-web-ui-v2"

type ActionProps = {
  icon: IconTypes
  onClick: () => void
}

export const Action = ({ icon, onClick }: ActionProps) => {
  return (
    <PillButton icon={icon} variant="default" size="small" onClick={onClick} />
  )
}
