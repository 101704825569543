import { trpc } from "@api/client"
import { Box, ButtonMenu, DropdownOptions } from "@atoms"
import { Steps } from "@customTypes/resignation"
import { Button, IconTypes, LinkButton } from "@flash-tecnologia/hros-web-ui-v2"
import i18n from "@i18n"
import { ArchiveModal } from "@organisms"
import dispatchToast from "@utils/dispatchToast"
import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { columnOrder } from "src/constants"

function getNextStep(currentStep: Steps): Steps | null {
  const currentIndex = columnOrder.findIndex(
    (item) => item.step === currentStep,
  )

  if (currentIndex === -1 || currentIndex === columnOrder.length - 1) {
    return null
  }

  return columnOrder[currentIndex + 1].step
}

enum StepPosition {
  Current = "current",
  Before = "before",
  After = "after",
}

function getStepPosition(
  stepToCompare: Steps,
  currentStep: Steps,
): StepPosition {
  const stepOrderMap = new Map(
    columnOrder.map(({ step }, index) => [step, index]),
  )

  const stepIndex = stepOrderMap.get(stepToCompare)
  const currentIndex = stepOrderMap.get(currentStep)

  if (stepIndex === undefined || currentIndex === undefined) {
    throw new Error("Invalid step provided.")
  }

  if (stepIndex < currentIndex) return StepPosition.Before
  if (stepIndex > currentIndex) return StepPosition.After
  return StepPosition.Current
}

const dropdownButtonIconMapper: Record<StepPosition, IconTypes> = {
  [StepPosition.After]: "IconArrowRight",
  [StepPosition.Before]: "IconArrowLeft",
  [StepPosition.Current]: "IconCheck",
}

const dropdownButtonVariantMapper: Record<
  StepPosition,
  DropdownOptions["variant"]
> = {
  [StepPosition.After]: "secondary",
  [StepPosition.Before]: "default",
  [StepPosition.Current]: "active",
}

interface FooterProps {
  resignationId: string
  currentStep?: Steps
}

export const Footer = ({ resignationId, currentStep }: FooterProps) => {
  const [archiveModal, setArchiveModal] = React.useState<{
    open: boolean
    callback?: () => Promise<void>
  }>({ open: false })

  const [t] = useTranslation("translations", {
    keyPrefix: "page.resignationStep",
  })

  const { mutateAsync: changeStep, error: changeStepError } =
    trpc.flow.changeStep.useMutation()

  React.useEffect(() => {
    if (!!changeStepError) {
      dispatchToast({
        content:
          changeStepError.data?.userFriendlyError?.message ??
          i18n.t("error.internalServerError"),
        type: "error",
      })
    }
  }, [changeStepError])

  const navigate = useNavigate()
  const utils = trpc.useUtils()

  const handleReturn = () => navigate(-1)

  const handleChangeStep = async (step: Steps) => {
    async function handleChangeStep() {
      await changeStep({ resignationId, step, position: 0 })
      dispatchToast({
        content: t("footer.stepChangedSuccessfully"),
        type: "success",
      })
      utils.resignation.getResignation.refetch({
        resignationId: resignationId,
      })
    }

    if (step === Steps.Archived) {
      setArchiveModal({
        open: true,
        callback: async () => await handleChangeStep(),
      })
      return
    }

    await handleChangeStep()
  }

  const handleNextStep = () => {
    if (!currentStep) return
    const nextStep = getNextStep(currentStep)
    if (!nextStep) return

    handleChangeStep(nextStep)
  }

  const handleCloseArchiveModal = () => {
    setArchiveModal({
      open: false,
    })
  }

  const canMoveNextStep =
    !!currentStep && ![Steps.Done, Steps.Archived].includes(currentStep)

  return (
    <Box
      justifyContent="space-between"
      alignItems="center"
      gap="xs"
      $width={"100%"}
    >
      <LinkButton variant="default" onClick={handleReturn}>
        {t("footer.back")}
      </LinkButton>
      <Box alignItems="center" gap="xs">
        <ButtonMenu
          button={{
            size: "medium",
            text: t("footer.jumpStep"),
            variant: "secondary",
            disabled: !currentStep,
          }}
          dropdown={{
            title: t("footer.moveStepTitle"),
            options: currentStep
              ? Object.values(Steps).map((step) => {
                  const stepPosition = getStepPosition(step, currentStep)
                  return {
                    onClick: () => handleChangeStep(step),
                    text: t(`${step}.title`),
                    variant: dropdownButtonVariantMapper[stepPosition],
                    icon: dropdownButtonIconMapper[stepPosition],
                  }
                })
              : [],
          }}
        />
        <Button
          variant="primary"
          onClick={() => handleNextStep()}
          disabled={!canMoveNextStep}
        >
          {t("footer.nextStep")}
        </Button>
      </Box>

      {archiveModal.open && (
        <ArchiveModal
          visible={archiveModal.open}
          onClose={handleCloseArchiveModal}
          onConfirm={archiveModal.callback}
        />
      )}
    </Box>
  )
}
