import { Box } from "@atoms"
import { ExitFormEnum, ExitTermsEnum } from "@customTypes/resignation"
import { Modal } from "@flash-tecnologia/flamingo-ui-modal"
import {
  DatePicker,
  dayjs,
  MultiSelect,
  SelectField,
  TextArea,
  TextField,
  Toggle,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import i18n from "@i18n"
import React from "react"
import { useTranslation } from "react-i18next"
import { useTheme } from "styled-components"
import { SelectOption } from "@customTypes"
import { emailMask } from "@utils/masks"
import { useUpsertEmployeeModalViewModel } from "./hooks/useUpsertEmployeeModalViewModel"

const exitFormFieldOptions = Object.values(ExitFormEnum).map((value) => ({
  label: i18n.t(`components.upsertEmployeeModal.form.exitForm.values.${value}`),
  value,
}))

const exitTermsFieldOptions = Object.values(ExitTermsEnum).map((value) => ({
  label: i18n.t(
    `components.upsertEmployeeModal.form.exitTerms.values.${value}`,
  ),
  value,
}))

type UpsertEmployeeModalProps = { visible: boolean; onClose: () => void }

export const UpsertEmployeeModal = ({
  visible,
  onClose,
}: UpsertEmployeeModalProps) => {
  const theme = useTheme()

  const [t] = useTranslation("translations", {
    keyPrefix: "components.upsertEmployeeModal",
  })

  const {
    form: {
      values: formValues,
      handleChange: formHandleChange,
      touched: formTouched,
      errors: formErrors,
      handleSubmit: formHandleSubmit,
    },
    setEmployeeSearchTerm,
    debouncedEmployeeSearchTerm,
    employeeOptions,
    isEmployeeOptionsLoading,
    setResponsibleSearchTerm,
    debouncedResponsibleSearchTerm,
    responsibleOptions,
    isResponsibleOptionsLoading,
    setFollowersSearchTerm,
    debouncedFollowersSearchTerm,
    followersOptions,
    isFollowersOptionsLoading,
    isCreateResignationCardLoading,
    handleClose,
  } = useUpsertEmployeeModalViewModel({ onClose })

  return (
    <Modal.Root
      size="large"
      open={visible}
      onOpenChange={(open) => !open && handleClose()}
    >
      <Modal.Content.Root>
        <Modal.Content.Title>{t("title")}</Modal.Content.Title>
        <Modal.Content.Description>
          <Typography variant="body4" style={{ whiteSpace: "pre" }}>
            {t("description")}
          </Typography>
        </Modal.Content.Description>
        <Box flexDirection="column" gap="xs">
          <SelectField
            value={formValues.employee?.name}
            onChange={(e) => setEmployeeSearchTerm(e.target.value)}
            onSelectChange={(_, option: SelectOption | null) => {
              if (!option) return
              formHandleChange("employee", {
                id: option?.value,
                name: option?.label,
              })
            }}
            searchable
            error={formTouched.employee && !!formErrors.employee}
            helperText={(formTouched.employee && formErrors.employee) || ""}
            label={t("form.employee.label")}
            noOptionsText={
              debouncedEmployeeSearchTerm?.length < 3
                ? t("form.employee.invalidSearchText")
                : isEmployeeOptionsLoading
                  ? t("form.employee.loadingText")
                  : t("form.employee.noOptionText")
            }
            fullWidth
            options={employeeOptions}
          />
          <SelectField
            value={formValues.responsible?.name}
            onChange={(e) => setResponsibleSearchTerm(e.target.value)}
            onSelectChange={(_, option: SelectOption | null) => {
              if (!option) return
              formHandleChange("responsible", {
                id: option?.value,
                name: option?.label,
              })
            }}
            searchable
            error={formTouched.responsible && !!formErrors.responsible}
            helperText={
              (formTouched.responsible && formErrors.responsible) || ""
            }
            label={t("form.responsible.label")}
            noOptionsText={
              debouncedResponsibleSearchTerm?.length < 3
                ? t("form.responsible.invalidSearchText")
                : isResponsibleOptionsLoading
                  ? t("form.responsible.loadingText")
                  : t("form.responsible.noOptionText")
            }
            fullWidth
            options={responsibleOptions}
          />

          <MultiSelect
            renderInput={() => null}
            value={formValues.followers as any}
            onChange={(e) => {
              setFollowersSearchTerm(e.target.value)
            }}
            onSelectChange={(_, options: SelectOption[] | null) => {
              formHandleChange(
                "followers",
                options?.map(({ label, value }) => ({
                  id: value,
                  name: label,
                })),
              )
              setFollowersSearchTerm("")
            }}
            error={formTouched.followers && !!formErrors.followers}
            helperText={(formTouched.followers && formErrors.followers) || ""}
            loadingText={t("form.followers.loadingText")}
            label={t("form.followers.label")}
            noOptionsText={
              debouncedFollowersSearchTerm?.length < 3
                ? t("form.followers.invalidSearchText")
                : isFollowersOptionsLoading
                  ? t("form.followers.loadingText")
                  : t("form.followers.noOptionText")
            }
            fullWidth
            limitTags={2}
            options={followersOptions}
          />
          <SelectField
            label={t("form.exitForm.label")}
            fullWidth
            value={formValues.exitForm}
            options={exitFormFieldOptions}
            onSelectChange={(_, { value }) =>
              formHandleChange("exitForm", value)
            }
            required
            error={formTouched.exitForm && Boolean(formErrors.exitForm)}
            helperText={formTouched.exitForm && formErrors.exitForm?.toString()}
          />
          <SelectField
            label={t("form.exitTerms.label")}
            fullWidth
            value={formValues.exitTerms}
            options={exitTermsFieldOptions}
            onSelectChange={(_, { value }) =>
              formHandleChange("exitTerms", value)
            }
            required
            searchable
            error={formTouched.exitTerms && Boolean(formErrors.exitTerms)}
            helperText={
              formTouched.exitTerms && formErrors.exitTerms?.toString()
            }
          />
          <div>
            <TextField
              fullWidth
              label={t("form.email")}
              value={formValues.email}
              onChange={(e) => {
                const value = emailMask(e.target.value)
                formHandleChange("email", value)
              }}
              error={formTouched.email && Boolean(formErrors.email)}
              helperText={formTouched.email && formErrors.email?.toString()}
            />
          </div>
          <DatePicker
            fullWidth
            label={t("form.requestDate")}
            value={formValues.requestDate}
            onDateChange={(value) => {
              if (!value || !dayjs(value).isValid()) return
              formHandleChange("requestDate", value.toISOString())
            }}
            required
            error={formTouched.requestDate && Boolean(formErrors.requestDate)}
            helperText={
              formTouched.requestDate && formErrors.requestDate?.toString()
            }
          />
          <Box
            gap="xs1"
            $px="xs"
            $py="xs2"
            $border={`${theme.borders.width.xs2} solid ${theme.colors.neutral[70]}`}
            $borderRadius={theme.borders.radius.m}
          >
            <Toggle
              size="small"
              checked={formValues.noticePeriod}
              onChange={() =>
                formHandleChange("noticePeriod", !formValues.noticePeriod)
              }
            />
            <Typography variant="body3" color="neutral.30" weight={600}>
              {t("form.noticePeriod")}
            </Typography>
          </Box>
          <DatePicker
            fullWidth
            label={t("form.terminationDate")}
            value={formValues.terminationDate}
            onDateChange={(value) => {
              if (!value || !dayjs(value).isValid()) return
              formHandleChange("terminationDate", value.toISOString())
            }}
            required
            error={
              formTouched.terminationDate && Boolean(formErrors.terminationDate)
            }
            helperText={
              formTouched.terminationDate &&
              formErrors.terminationDate?.toString()
            }
          />
          <TextArea
            placeholder={t("form.motivation")}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              formHandleChange("motivation", e.target.value)
            }
            maxLength={100}
          />
        </Box>
        <Modal.Content.Footer>
          <Modal.Content.Footer.CancelButton onClick={handleClose}>
            {t("buttons.cancel")}
          </Modal.Content.Footer.CancelButton>
          <Modal.Content.Footer.PrimaryButton
            onClick={() => formHandleSubmit()}
            loading={isCreateResignationCardLoading}
          >
            {t("buttons.submit")}
          </Modal.Content.Footer.PrimaryButton>
        </Modal.Content.Footer>
      </Modal.Content.Root>
    </Modal.Root>
  )
}
