export enum ExitFormEnum {
  Voluntary = "voluntary",
  Involuntary = "involuntary",
}

export enum ExitTermsEnum {
  WithoutCause = "termination-without-cause",
  ForCause = "termination-for-cause",
  MutualAgreement = "mutual-termination",
  Resignation = "employee-resignation",
}

export enum Steps {
  Initiated = "initial-info",
  Evaluation = "evaluation",
  Formalization = "formalization",
  ExitExam = "exit-exam",
  Signature = "signature",
  Done = "done",
  Archived = "archived",
}

export interface Card {
  position: number
}

export type File = {
  path: string
}

export enum VoluntaryTerminationReason {
  Compensation = "compensation",
  Benefits = "benefits",
  CulturalFit = "cultural-fit",
  LackOfGrowthOpportunity = "lack-of-growth-opportunity",
  CareerChange = "career-change",
  MisalignedActivities = "misaligned-activities",
  LackOfManagementSupport = "lack-of-management-support",
  ManagementChange = "management-change",
  LackOfRecognition = "lack-of-recognition",
  AreaChange = "area-change",
  Other = "other",
}

export enum InvoluntaryTerminationReason {
  Behavior = "behavior",
  Performance = "performance",
  DisciplinaryAction = "disciplinary-action",
  LackOfCulturalFit = "lack-of-cultural-fit",
  LackOfAdaptation = "lack-of-adaptation",
  StrategyOrStructureChange = "strategy-or-structure-change",
  InternshipCompletion = "internship-completion",
}

export enum EvaluationStatus {
  NotStarted = "not-started",
  InProgress = "in-progress",
  Done = "done",
}

export interface Evaluation {
  status?: EvaluationStatus
  date?: Date
  responsiblesIds?: string[]
  notes?: string
  attachment?: File
  reason?: VoluntaryTerminationReason | InvoluntaryTerminationReason
}

export enum FormalizationStatus {
  PendingEmail = "PendingEmail",
  InProgress = "InProgress",
  Done = "Done",
}

export interface Resignation {
  id: string
  step: Steps
  employeeId: string
  companyId: string
  responsibleId: string
  followers: string[]
  exitForm: ExitFormEnum
  email?: string
  requestDate: Date
  exitTerms: ExitTermsEnum
  noticePeriod?: boolean
  terminationDate: Date
  motivation?: string
  createdAt?: Date
  updatedAt?: Date
  card: Card
  evaluation?: Evaluation
  formalization?: Formalization
}

type FollowerEmployee = {
  id: string
  name: string
  profilePicture?: string
}

interface FormalizationExternalLink {
  dueDate: Date
  url: string
}

enum FormalizationLetterStatus {
  Approved = "approved",
  Rejected = "rejected",
  Sent = "sent",
}

export interface Formalization {
  attachment?: File
  email?: string
  externalLink?: FormalizationExternalLink
  letter?: File
  letterStatus?: FormalizationLetterStatus
  rejectReason?: string
  shouldRequestLetter: boolean
  status: FormalizationStatus
}

export type GetResignationResponse = Omit<Resignation, "followers"> & {
  employeeName: string
  employeePicture?: string
  responsibleName: string
  responsiblePicture?: string
  followers: FollowerEmployee[]
}
