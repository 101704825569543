import { Tag } from "@flash-tecnologia/hros-web-ui-v2"
import { StepPageHeader } from "@organisms"
import { PageBody } from "@templates"
import { StepPage } from "@templates/StepPage"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { BodyRightContent } from "./components/BodyRightContent"
import { Footer } from "./components/Footer"
import { LoadingState } from "./components/LoadingState"
import { useResignationStepPage } from "./hooks/useResignationStepPage"

export const ResignationStep = () => {
  const [t] = useTranslation("translations", {
    keyPrefix: "page.resignationStep",
  })
  const navigate = useNavigate()

  const { resignationId = "" } = useParams()

  if (!resignationId) {
    navigate("/resignation", { replace: true })
    return null
  }

  const {
    isGetResignationLoading,
    navigationHeaderProps,
    PageLeftContent,
    resignation,
    tagProps,
  } = useResignationStepPage()

  if (isGetResignationLoading) {
    return <LoadingState />
  }

  if (!resignation) {
    navigate("/resignation", { replace: true })
    return null
  }

  return (
    <StepPage.Root>
      <StepPage.NavigationHeader {...navigationHeaderProps} />
      <StepPage.Body>
        <PageBody.Root>
          <PageBody.Header>
            <StepPageHeader.Root>
              <StepPageHeader.Disclaimer>
                {t("body.header.disclaimer")}
              </StepPageHeader.Disclaimer>
              <StepPageHeader.Title>
                {t(`${resignation.step}.title`)}
              </StepPageHeader.Title>
              <StepPageHeader.Subtitle>
                {t(`${resignation.step}.subtitle`)}
              </StepPageHeader.Subtitle>
              <StepPageHeader.DefinitionList>
                {tagProps ? (
                  <StepPageHeader.DefinitionList.Item>
                    <StepPageHeader.DefinitionList.Item.DT>
                      {t("body.header.definitionList.status")}
                    </StepPageHeader.DefinitionList.Item.DT>
                    <StepPageHeader.DefinitionList.Item.DD>
                      <Tag {...tagProps} />
                    </StepPageHeader.DefinitionList.Item.DD>
                  </StepPageHeader.DefinitionList.Item>
                ) : (
                  <></>
                )}
                <StepPageHeader.DefinitionList.Item>
                  <StepPageHeader.DefinitionList.Item.DT>
                    {t("body.header.definitionList.responsible")}
                  </StepPageHeader.DefinitionList.Item.DT>
                  <StepPageHeader.DefinitionList.Item.DD>
                    <Tag variant="primary">{resignation.responsibleName}</Tag>
                  </StepPageHeader.DefinitionList.Item.DD>
                </StepPageHeader.DefinitionList.Item>
                {resignation.followers?.length ? (
                  <StepPageHeader.DefinitionList.Item>
                    <StepPageHeader.DefinitionList.Item.DT>
                      {t("body.header.definitionList.followers")}
                    </StepPageHeader.DefinitionList.Item.DT>
                    <StepPageHeader.DefinitionList.Item.DD>
                      {resignation.followers?.map((follower) => (
                        <Tag key={follower.id} variant="primary">
                          {follower.name}
                        </Tag>
                      ))}
                    </StepPageHeader.DefinitionList.Item.DD>
                  </StepPageHeader.DefinitionList.Item>
                ) : (
                  <></>
                )}
              </StepPageHeader.DefinitionList>
            </StepPageHeader.Root>
          </PageBody.Header>
          <PageBody.LeftContent>{PageLeftContent}</PageBody.LeftContent>
          <PageBody.RightContent>
            {resignation.employeeId && (
              <BodyRightContent employeeId={resignation.employeeId} />
            )}
          </PageBody.RightContent>
        </PageBody.Root>
      </StepPage.Body>
      <StepPage.Footer>
        <Footer />
      </StepPage.Footer>
    </StepPage.Root>
  )
}
