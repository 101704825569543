import { Box } from "@atoms"

type RootProps = {
  children: React.ReactNode
}

export const Root = ({ children }: RootProps) => {
  return (
    <Box $width="100%" flexDirection="column" gap="xs3" alignItems="center">
      {children}
    </Box>
  )
}
