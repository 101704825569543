import { Typography } from "@flash-tecnologia/hros-web-ui-v2"

type NameProps = {
  name: string
}

export const Name = ({ name }: NameProps) => {
  return (
    <Typography variant="headline5" color="secondary.50">
      {name}
    </Typography>
  )
}
