import React from "react"
import { Box } from "@atoms/Box"
import { Dropzone, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import { DropzoneFile } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Dropzone/types"
import dispatchToast from "../../../utils/dispatchToast"
import { useTranslation } from "react-i18next"
import { Preview } from "./Preview"
import { Loading } from "./Loading"
import { Action } from "./Action"
import { Error } from "./Error"
import { trpc } from "@api/client"
import { uploadFileToS3 } from "@flash-tecnologia/hros-web-utility"
import { File as FileType } from "@customTypes/resignation"

export type UploadProps = {
  value?: FileType
  label?: string
  placeholder?: string
  onUpload?: (file: FileType) => Promise<void>
  accept: string[]
  folder: string
  maxSize?: number
  children?: React.ReactNode
}

const TEN_MEGABYTES = 10 * 1024 * 1024

function isFileSizeValid(file: File) {
  if (file.size > TEN_MEGABYTES) {
    return false
  }

  return true
}

const Root = ({
  label,
  placeholder,
  value,
  accept,
  onUpload,
  folder,
  maxSize,
  children,
}: UploadProps) => {
  const [t] = useTranslation("translations", {
    keyPrefix: "page.components.upload",
  })

  const { mutateAsync: getPresignedUrlMutate, error } =
    trpc.utils.getPresignedUrl.useMutation()

  const handleChange = async (files: DropzoneFile[]) => {
    if (files.length > 1) {
      dispatchToast({
        content: t("errorMultipleFiles"),
        type: "error",
      })
      return
    }

    const file = files[0]
    if (!isFileSizeValid(file.file)) {
      dispatchToast({
        content: t("errorArchiveSize"),
        type: "error",
      })
      return
    }

    try {
      const path = `${folder}/${Date.now()}-${file.file.name}`
      const url = await getPresignedUrlMutate({
        path,
        command: "put",
      })
      await uploadFileToS3({
        file: file.file,
        url,
      })
      onUpload?.({
        path,
      })
    } catch (err) {
      dispatchToast({
        content: t("errorUpload"),
        type: "error",
      })
    }
  }

  if (value) {
    return <Preview file={value}>{children}</Preview>
  }

  if (error) {
    return <Error />
  }

  return (
    <Box flexDirection="column" gap="xs4">
      {label && (
        <Typography variant="body3" weight={600} color="neutral.30">
          {label}
        </Typography>
      )}

      <Dropzone
        accept={accept}
        title={placeholder}
        multiple={false}
        onChange={handleChange}
        maxSize={maxSize}
        customPreview={() => <Loading />}
        onFileSizeError={() => {
          dispatchToast({
            content: t("errorArchiveSize"),
            type: "error",
          })
        }}
      />
    </Box>
  )
}

export const Upload = {
  Root: Root,
  Action: Action,
}
