import { Box, ButtonMenu } from "@atoms"
import { Button, LinkButton } from "@flash-tecnologia/hros-web-ui-v2"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

interface FooterProps {
  onNextStepClick?: () => void
}

export const Footer = ({ onNextStepClick }: FooterProps) => {
  const [t] = useTranslation("translations", {
    keyPrefix: "page.resignationStep.footer",
  })

  const navigate = useNavigate()

  const handleReturn = () => navigate(-1)

  return (
    <Box justifyContent="space-between" alignItems="center" gap="xs" $width={"100%"}>
      <LinkButton variant="default" onClick={handleReturn}>
        {t("back")}
      </LinkButton>
      <Box alignItems="center" gap="xs">
        <ButtonMenu
          button={{
            size: "medium",
            text: t("jumpStep"),
            variant: "secondary",
            disabled: true,
          }}
          dropdown={{
            title: "",
            options: [],
          }}
        />
        <Button variant="primary" onClick={onNextStepClick}>
          {t("nextStep")}
        </Button>
      </Box>
    </Box>
  )
}
