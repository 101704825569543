import React from "react"
import { Icons } from "@flash-tecnologia/hros-web-ui-v2"
import dispatchToast from "@utils/dispatchToast"
import { useTranslation } from "react-i18next"
import { useTheme } from "styled-components"

type ClipboardProps = {
  value: string
}

export const Clipboard = ({ value }: ClipboardProps) => {
  const [copied, setCopied] = React.useState<boolean>(false)

  const theme = useTheme()
  const [t] = useTranslation("translations", {
    keyPrefix: "components.clipboard",
  })

  const handleClick = () => {
    setCopied(true)
    navigator.clipboard.writeText(value)
    dispatchToast({
      content: t("textCopied"),
      type: "success",
    })

    setTimeout(() => {
      setCopied(false)
    }, 500)
  }

  return (
    <div role="presentation" onClick={handleClick}>
      <Icons
        name={copied ? "IconSquareCheck" : "IconSquare"}
        fill={theme.colors.neutral[50]}
      />
    </div>
  )
}
