import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import * as SC from "./styles"

export interface SubtitleProps {
  children: string
}
export const Subtitle = ({ children }: SubtitleProps) => {
  return (
    <SC.TitleWrapper>
      <Typography.Body4 color={"neutral.50"} weight={400}>
        {children}
      </Typography.Body4>
    </SC.TitleWrapper>
  )
}
