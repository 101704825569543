import React from "react"
import { Container, EllipsisTypography, PreviewImage } from "./styles"
import { Box } from "@atoms/Box"
import { File as FileType } from "@customTypes/resignation"
import { trpc } from "@api/client"
import { Skeleton } from "@mui/material"

type PreviewProps = {
  file: FileType
  children: React.ReactNode
}

export interface PreviewState {
  url?: string
}

export const previewContext = React.createContext<PreviewState>(
  {} as PreviewState,
)

export const Preview = ({ file, children }: PreviewProps) => {
  const [url, setUrl] = React.useState<string | null>(null)

  const { mutateAsync: getPresignedUrlMutate } =
    trpc.utils.getPresignedUrl.useMutation()

  const nameOfArchive = React.useMemo(() => {
    const lastIndexName = file?.path?.lastIndexOf("/") || ""
    if (file?.path && lastIndexName) {
      const keyFileName = file?.path.substring(lastIndexName + 1)
      return keyFileName
    }
  }, [file])

  React.useEffect(() => {
    getPresignedUrlMutate({ path: file.path, command: "get" }).then((url) => {
      setUrl(url)
    })
  }, [file])

  return (
    <Container>
      {url ? (
        <PreviewImage url={url} />
      ) : (
        <Skeleton variant="rectangular" width={45} height={45} />
      )}

      <EllipsisTypography variant="body3" weight={700} color="neutral.30">
        {nameOfArchive}
      </EllipsisTypography>

      <Box gap="xs3">
        <previewContext.Provider value={{ url: url || undefined }}>
          {children}
        </previewContext.Provider>
      </Box>
    </Container>
  )
}
