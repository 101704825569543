import { trpc } from "@api/client"
import { Box } from "@atoms"
import { ResignationStatus, Steps } from "@customTypes/resignation"
import i18n from "@i18n"
import { mapResignationStatusToTagProps } from "@pages/Home/ResignationKanban/utils/mapResignationStatusToTagProps"
import { NavigationHeaderProps } from "@templates/StepPage/NavigationHeader"
import dispatchToast from "@utils/dispatchToast"
import { ReactElement, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import {
  StepDetailsSection as InitialInfoStepDetailsSection,
  StepDetailsSection,
} from "../components/steps/initialInfo/StepDetailsSection"
import { TagProps } from "../types"
import { Interview } from "../components/steps/Interview"

export function useResignationStepPage() {
  const [t] = useTranslation("translations", {
    keyPrefix: "page.resignationStep",
  })
  const navigate = useNavigate()

  const { resignationId = "" } = useParams()

  const {
    isLoading: isGetResignationLoading,
    data: resignation,
    error: getResignationError,
  } = trpc.resignation.getResignation.useQuery({ resignationId })

  useEffect(() => {
    if (getResignationError) {
      dispatchToast({
        content:
          getResignationError.data?.userFriendlyError?.message ??
          i18n.t("error.internalServerError"),
        type: "error",
      })
    }
  }, [getResignationError])

  const PageLeftContent:
    | ReactElement<typeof InitialInfoStepDetailsSection>
    | undefined =
    resignation &&
    {
      [Steps.InitialInfo]: (
        <Box $mb="m" $width={"100%"}>
          <StepDetailsSection
            employeeEmail={resignation.email}
            exitForm={resignation.exitForm}
            exitTerms={resignation.exitTerms}
            motivation={resignation.motivation}
            noticePeriod={resignation.noticePeriod}
            onEditClick={() => {}}
            requestDate={resignation.requestDate}
            requestedBy={resignation.responsibleName}
            terminationDate={resignation.terminationDate}
          />
        </Box>
      ),
      [Steps.Evaluation]: (
        <Box $mb="m" $width={"100%"}>
          <Interview
            resignationId={resignationId}
            exitForm={resignation.exitForm}
            initialInterview={resignation.interview}
          />
        </Box>
      ),
      [Steps.Instructions]: undefined,
      [Steps.ExitExam]: undefined,
      [Steps.Signature]: undefined,
      [Steps.Done]: undefined,
      [Steps.Archived]: undefined,
    }[resignation?.step]

  if (!resignationId) {
    navigate("/resignation", { replace: true })
    return {}
  }

  const navigationHeaderProps: NavigationHeaderProps = {
    navigation: [
      {
        label: t("navigation.home"),
        path: "/resignation",
      },
      {
        label: t(`${resignation?.step}.title`),
      },
    ],
  }

  let tagProps: TagProps | undefined

  if (resignation?.status === ResignationStatus.SignaturePendingSignature) {
    tagProps = mapResignationStatusToTagProps({
      status: ResignationStatus.SignaturePendingSignature,
      pendingSignaturesCount: 1,
    })
  } else {
    tagProps =
      resignation &&
      mapResignationStatusToTagProps({ status: resignation.status })
  }

  return {
    isGetResignationLoading,
    navigationHeaderProps,
    PageLeftContent,
    resignation: {
      employeeId: resignation?.employeeId,
      responsibleName: resignation?.responsibleName,
      status: resignation?.status,
      step: resignation?.step,
      followers: resignation?.followers,
    },
    tagProps,
  }
}
