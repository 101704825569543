import { Box } from "@atoms/Box"
import { ReactNode } from "react"
import { CSSProperties, DefaultTheme, useTheme } from "styled-components"

interface CardProps {
  $width?: CSSProperties["width"]
  $px?: keyof DefaultTheme["spacings"]
  $py?: keyof DefaultTheme["spacings"]
  children: ReactNode
}

export const Card = ({ $width, $px = "m", $py = "m", children }: CardProps) => {
  const theme = useTheme()
  return (
    <Box
      $border={`${theme.borders.width.xs2} solid ${theme.colors.neutral[90]}`}
      $borderRadius={theme.borders.radius.m}
      $px={$px}
      $py={$py}
      $width={$width}
    >
      {children}
    </Box>
  )
}
