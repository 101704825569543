import { Steps } from "@customTypes/resignation"

export type StepOrder = { step: Steps; order: number }

export const columnOrder: readonly StepOrder[] = [
  { step: Steps.Initiated, order: 1 },
  { step: Steps.Evaluation, order: 2 },
  { step: Steps.Formalization, order: 3 },
  { step: Steps.ExitExam, order: 4 },
  { step: Steps.Signature, order: 5 },
  { step: Steps.Done, order: 6 },
  { step: Steps.Archived, order: 7 },
] as const
