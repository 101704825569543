import { trpc } from "@api/client"
import { DetailItemTypes } from "@molecules"
import PersonDetails from "@organisms/PersonDetails"
import { dayjs } from "@utils"
import { CpfMask, PhoneMask } from "@utils/masks"
import React from "react"
import { useTranslation } from "react-i18next"

type DetailProps = React.ComponentProps<typeof PersonDetails.Detail>

export function usePersonDetails({
  employeeId,
  communicationDate,
}: {
  employeeId: string
  communicationDate?: Date
}) {
  const { data: employee, isLoading } = trpc.employee.getEmployee.useQuery({
    id: employeeId,
  })

  const [t] = useTranslation("translations", {
    keyPrefix: "page.resignationStep.card.personDetails",
  })

  const defaultValue = t("values.default")

  const personDetails: Record<string, DetailProps> = {
    fullName: {
      icon: "IconUser",
      label: t("labels.fullName"),
      value: employee?.name ?? defaultValue,
    },
    documentNumber: {
      label: t("labels.cpf"),
      value: employee?.documentNumber
        ? CpfMask(employee.documentNumber)
        : defaultValue,
      icon: "IconId",
    },
    registration: {
      label: t("labels.registration"),
      value: employee?.externalId || defaultValue,
      icon: "IconId",
    },
    leader: {
      label: t("labels.leader"),
      value: employee?.manager?.name ?? defaultValue,
      icon: "IconUserPlus",
    },
    email: {
      label: t("labels.email"),
      value: employee?.email ?? defaultValue,
      icon: "IconMail",
    },
    phoneNumber: {
      label: t("labels.phone"),
      value: employee?.phoneNumber
        ? PhoneMask({ value: employee.phoneNumber })
        : defaultValue,
      icon: "IconPhone",
    },
    admissionDate: {
      label: t("labels.admissionDate"),
      value: employee?.startDate
        ? dayjs(employee.startDate).format("DD/MM/YYYY")
        : defaultValue,
      icon: "IconCalendar",
    },
    communicationDate: {
      label: t("labels.communicationDate"),
      type: !communicationDate ? DetailItemTypes.Error : undefined,
      value: communicationDate
        ? dayjs(communicationDate).format("DD/MM/YYYY")
        : defaultValue,
      icon: "IconCalendar",
    },
  }

  return {
    isLoading,
    personDetails,
    profilePicture: employee?.profilePicture,
  }
}
