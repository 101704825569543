import { ResignationStatus } from "@customTypes/resignation"
import i18n from "@i18n"
import { TagProps } from "@pages/ResignationStep/types"

const mapStatusToTags: Partial<Record<ResignationStatus, TagProps>> = {
  [ResignationStatus.EvaluationCompleted]: {
    variant: "success",
    children: i18n.t(`page.components.resignationStatusTag.${ResignationStatus.EvaluationCompleted}`),
  } as TagProps,
  [ResignationStatus.EvaluationInProgress]: {
    variant: "info",
    children: i18n.t(`page.components.resignationStatusTag.${ResignationStatus.EvaluationInProgress}`),
  } as TagProps,
  [ResignationStatus.EvaluationPendingInterview]: {
    variant: "error",
    children: i18n.t(`page.components.resignationStatusTag.${ResignationStatus.EvaluationPendingInterview}`),
  } as TagProps,
  [ResignationStatus.InstructionsCompleted]: {
    variant: "success",
    children: i18n.t(`page.components.resignationStatusTag.${ResignationStatus.InstructionsCompleted}`),
  } as TagProps,
  [ResignationStatus.InstructionsInProgress]: {
    variant: "info",
    children: i18n.t(`page.components.resignationStatusTag.${ResignationStatus.InstructionsInProgress}`),
  } as TagProps,
  [ResignationStatus.InstructionsPendingEmail]: {
    variant: "error",
    children: i18n.t(`page.components.resignationStatusTag.${ResignationStatus.InstructionsPendingEmail}`),
  } as TagProps,
  [ResignationStatus.ExitExamAwaitingPersonResponse]: {
    variant: "info",
    children: i18n.t(`page.components.resignationStatusTag.${ResignationStatus.ExitExamAwaitingPersonResponse}`),
  } as TagProps,
  [ResignationStatus.ExitExamCompleted]: {
    variant: "success",
    children: i18n.t(`page.components.resignationStatusTag.${ResignationStatus.ExitExamCompleted}`),
  } as TagProps,
  [ResignationStatus.ExitEmailPendingRequest]: {
    variant: "error",
    children: i18n.t(`page.components.resignationStatusTag.${ResignationStatus.ExitEmailPendingRequest}`),
  } as TagProps,
  [ResignationStatus.ExitExamPendingValidation]: {
    variant: "error",
    children: i18n.t(`page.components.resignationStatusTag.${ResignationStatus.ExitExamPendingValidation}`),
  } as TagProps,
  [ResignationStatus.InitialInfoCompleted]: {
    variant: "success",
    children: i18n.t(`page.components.resignationStatusTag.${ResignationStatus.InitialInfoCompleted}`),
  } as TagProps,
  [ResignationStatus.InitialInfoInApproval]: {
    variant: "info",
    children: i18n.t(`page.components.resignationStatusTag.${ResignationStatus.InitialInfoInApproval}`),
  } as TagProps,
  [ResignationStatus.InitialInfoPendingCommunication]: {
    variant: "error",
    children: i18n.t(`page.components.resignationStatusTag.${ResignationStatus.InitialInfoPendingCommunication}`),
  } as TagProps,
  [ResignationStatus.InitialInfoRejected]: {
    variant: "error",
    children: i18n.t(`page.components.resignationStatusTag.${ResignationStatus.InitialInfoRejected}`),
  } as TagProps,
  [ResignationStatus.SignaturePendingContract]: {
    variant: "error",
    children: i18n.t(`page.components.resignationStatusTag.${ResignationStatus.SignaturePendingContract}`),
  } as TagProps,
  [ResignationStatus.SignatureSigned]: {
    variant: "success",
    children: i18n.t(`page.components.resignationStatusTag.${ResignationStatus.SignatureSigned}`),
  } as TagProps,
}

type Params =
  | {
      status: Exclude<ResignationStatus, ResignationStatus.SignaturePendingSignature>
    }
  | {
      status: ResignationStatus.SignaturePendingSignature
      pendingSignaturesCount: number
    }

export const mapResignationStatusToTagProps = (params: Params): TagProps | undefined => {
  if (params.status === ResignationStatus.SignaturePendingSignature) {
    return {
      variant: "error",
      children: i18n.t(`page.components.resignationStatusTag.${ResignationStatus.SignaturePendingSignature}`, {
        pendingSignaturesCount: params.pendingSignaturesCount,
      }),
    } as TagProps
  }

  return mapStatusToTags[params.status]
}
