import { Box } from "@atoms/Box"
import { Tag } from "@flash-tecnologia/hros-web-ui-v2"
import { ReactElement } from "react"

export interface DefinitionDescriptionProps {
  children: ReactElement<typeof Tag> | ReactElement<typeof Tag>[]
}

export const DefinitionDescription = ({ children }: DefinitionDescriptionProps) => {
  return <Box gap="xs4">{children}</Box>
}
