import { Home, ResignationStep } from "@pages"
import { BrowserRouter, Route, Routes } from "react-router-dom"

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/resignation" element={<Home />} />
        <Route
          path="/resignation/step/:resignationId"
          element={<ResignationStep />}
        />
      </Routes>
    </BrowserRouter>
  )
}

export default AppRouter
