import { Icons } from "@flash-tecnologia/hros-web-ui-v2"
import { useTranslation } from "react-i18next"
import {
  Container,
  Content,
  EllipsisTypography,
  ProgressBar,
  Wrapper,
} from "./styles"

export const Loading = () => {
  const { t } = useTranslation("translations", {
    keyPrefix: "page.components.upload",
  })

  return (
    <Container>
      <Wrapper>
        <Icons size={32} name={"IconFile"} fill="transparent" />

        <Content>
          <EllipsisTypography variant="body3" weight={700} color="neutral.30">
            {t("loadingTitle")}
          </EllipsisTypography>

          <ProgressBar />
        </Content>
      </Wrapper>
    </Container>
  )
}
