import { GenericProfilePicture } from "@flash-tecnologia/hros-web-ui-v2"

type PictureProps = {
  name: string
  source: string
}

export const Picture = ({ name, source }: PictureProps) => {
  return <GenericProfilePicture name={name} source={source} size={150} />
}
