import { PhoneNumberFormat, PhoneNumberUtil } from "google-libphonenumber"

const phoneUtil = PhoneNumberUtil.getInstance()

export const emailMask = (emailValue: string): string => {
  if (!emailValue) return ""
  return emailValue
    .replace(/\s/g, "")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[^\w\.@|-|+-]/g, "")
    .toLowerCase()
}

export const CpfMask = (cpfValue: string): string => {
  if (!cpfValue) return ""
  return cpfValue
    .replace(/\D+/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})$/, "$1-$2")
}

export const PhoneMask = ({
  value,
  countryCode = "BR",
  phoneNumberFormat = PhoneNumberFormat.NATIONAL,
}: {
  value: string
  countryCode?: string
  phoneNumberFormat?: PhoneNumberFormat
}): string => {
  if (!value) return ""
  const parsedNumber =
    value.length > 2 ? phoneUtil.parseAndKeepRawInput(value, countryCode) : ""

  if (!parsedNumber) return value

  const isValid = parsedNumber ? phoneUtil.isValidNumber(parsedNumber) : false

  if (isValid) {
    return phoneUtil.format(parsedNumber, phoneNumberFormat)
  }

  return value.replace(/\D/g, "")
}
