import { IconTypes, PillButton } from "@flash-tecnologia/hros-web-ui-v2"
import React from "react"
import i18n from "@i18n"
import dispatchToast from "@utils/dispatchToast"
import { previewContext } from "../Preview"

export type ClickParams = {
  url: string
}

type ActionProps = {
  icon: IconTypes
  onClick: (params: ClickParams) => void
}

export const Action = ({ icon, onClick }: ActionProps) => {
  const { url } = React.useContext(previewContext)

  const handleClick = () => {
    if (!url) {
      dispatchToast({
        content: i18n.t("error.internalServerError"),
        type: "error",
      })
      return
    }
    const params: ClickParams = { url }
    onClick(params)
  }

  return (
    <PillButton
      icon={icon}
      variant="default"
      size="medium"
      onClick={handleClick}
    />
  )
}
