import { Box } from "@atoms"
import { Card } from "@atoms/Card"
import { File, Steps } from "@customTypes/resignation"
import {
  DatePicker,
  Icons,
  Radio,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import { Upload } from "@molecules"
import { forwardRef } from "react"
import { useTranslation } from "react-i18next"
import { useTheme } from "styled-components"
import { FormRef } from "../../hooks/useFormalizationStep"
import { useLetterForm } from "../../hooks/useLetterForm"
import * as SC from "./styles"
import { dayjs } from "@utils"

export interface LetterForm {
  alreadyHaveLetter?: boolean
  letter?: File
  sendingLetterDeadline?: Date
  shouldRequestLetter?: boolean
}

export interface LetterSectionProps {
  initialAlreadyHaveLetter?: boolean
  initialLetter?: File
  initialSendingLetterDeadline?: Date
  initialShouldRequestLetter?: boolean
}

export const LetterSection = forwardRef<
  FormRef<LetterForm>,
  LetterSectionProps
>(
  (
    {
      initialAlreadyHaveLetter = false,
      initialLetter,
      initialSendingLetterDeadline,
      initialShouldRequestLetter = false,
    },
    ref,
  ) => {
    const theme = useTheme()

    const {
      alreadyHaveLetter,
      alreadyHaveLetterErrorMessage,
      letter,
      letterErrorMessage,
      sendingLetterDeadline,
      sendingLetterDeadlineErrorMessage,
      shouldRequestLetter,
      onAlreadyHaveLetterChange,
      onLetterDelete,
      onLetterUpload,
      onSendingLetterDeadlineBlur,
      onSendingLetterDeadlineChange,
      onShouldRequestLetterChange,
    } = useLetterForm({
      initialAlreadyHaveLetter,
      initialLetter,
      initialSendingLetterDeadline,
      initialShouldRequestLetter,
      letterFormRef: ref,
    })

    const [t] = useTranslation("translations", {
      keyPrefix: `page.resignationStep.${Steps.Formalization}.letterSection`,
    })

    return (
      <Box $width="100%" gap="xs1" flexDirection="column">
        <div>
          <Typography.Headline8 color="neutral.30" weight={700}>
            {t("title")}
          </Typography.Headline8>
          <Typography.Body4 color="neutral.50" weight={400}>
            {t("description")}
          </Typography.Body4>
        </div>
        <Card $width="100%" $px="xs" $py="xs2">
          <SC.RadioAccordionWrapper>
            <Radio
              onChange={onShouldRequestLetterChange}
              checked={shouldRequestLetter}
            />
            <div>
              <Typography.Body3 color="neutral.30" weight={600}>
                {t("form.fields.shouldRequestLetter.label")}
              </Typography.Body3>
              <Typography.Body4 color="neutral.50" weight={400}>
                {t("form.fields.shouldRequestLetter.description")}
              </Typography.Body4>
            </div>
            {shouldRequestLetter && (
              <SC.RadioAccordionContent>
                <DatePicker
                  label={t("form.fields.deadline.label")}
                  value={sendingLetterDeadline}
                  onBlur={onSendingLetterDeadlineBlur}
                  required
                  fromDate={dayjs()}
                  onDateChange={onSendingLetterDeadlineChange}
                  error={!!sendingLetterDeadlineErrorMessage}
                  helperText={sendingLetterDeadlineErrorMessage}
                  InputProps={{
                    endAdornment: (
                      <Icons
                        name="IconCalendarEvent"
                        color={theme.colors.neutral[50]}
                      />
                    ),
                  }}
                />
              </SC.RadioAccordionContent>
            )}
          </SC.RadioAccordionWrapper>
        </Card>
        <Card $width="100%" $px="xs" $py="xs2">
          <Box $width="100%" gap="xs2" flexDirection="column">
            <SC.RadioAccordionWrapper>
              <Radio
                onChange={onAlreadyHaveLetterChange}
                checked={alreadyHaveLetter}
              />
              <div>
                <Typography.Body3 color="neutral.30" weight={600}>
                  {t("form.fields.alreadyHaveRequestLetter.label")}
                </Typography.Body3>
                <Typography.Body4 color="neutral.50" weight={400}>
                  {t("form.fields.alreadyHaveRequestLetter.description")}
                </Typography.Body4>
              </div>
              {alreadyHaveLetter && (
                <SC.RadioAccordionContent>
                  <Upload.Root
                    error={!!letterErrorMessage}
                    folder="interview"
                    label={t("form.fields.requestLetter.label")}
                    placeholder={t(
                      "form.fields.requestLetter.uploader.placeholder",
                    )}
                    value={letter}
                    onUpload={onLetterUpload}
                    accept={["png", "jpg", "pdf"]}
                  >
                    <Upload.Action
                      icon={"IconTrash"}
                      onClick={onLetterDelete}
                    />
                  </Upload.Root>
                </SC.RadioAccordionContent>
              )}
            </SC.RadioAccordionWrapper>
          </Box>
        </Card>
        {alreadyHaveLetterErrorMessage && (
          <Box gap="xs5">
            <Icons
              name="IconInfoCircle"
              color={theme.colors.feedback.error[50]}
              size={16}
            />
            <Typography.Caption color="feedback.error.40" weight={600}>
              {alreadyHaveLetterErrorMessage}
            </Typography.Caption>
          </Box>
        )}
      </Box>
    )
  },
)
