import {
  LinkButton,
  ShapeIcon,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import { useTheme } from "styled-components"
import { Container } from "./styles"
import { Box, EllipsisTypography } from "@atoms"
import { useTranslation } from "react-i18next"

type ErrorProps = {
  archiveName: string
  onRetry: () => void
}

export const Error = ({ archiveName, onRetry }: ErrorProps) => {
  const theme = useTheme()

  const [t] = useTranslation("translations", {
    keyPrefix: "page.components.upload",
  })

  return (
    <Container>
      <ShapeIcon
        name="IconUpload"
        variant="error"
        color={theme.colors.feedback.error[40]}
        size={64}
      />

      <Box
        flexDirection="column"
        gap="xs5"
        alignItems="center"
        $mt="xs3"
        $mb="xs"
      >
        <EllipsisTypography variant="body3" weight={700} color="neutral.30">
          {archiveName}
        </EllipsisTypography>
        <Typography variant="body3" weight={400} color="neutral.30">
          {t("errorLabel")}
        </Typography>
      </Box>

      <LinkButton variant="error" size="medium" type="button" onClick={onRetry}>
        {t("errorRetryButton")}
      </LinkButton>
    </Container>
  )
}
