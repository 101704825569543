import styled, { css } from "styled-components"

export const Container = styled.div``

export const DropdownContainer = styled.div`
  background: ${({ theme }) => theme.colors.neutral[100]};
  box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.24);
  border-radius: ${({ theme }) => theme.borders.radius.s};
  padding: ${({ theme }) => theme.spacings.xs3};
  gap: ${({ theme }) => theme.spacings.xs3};

  width: 305px;
  position: absolute;
  z-index: 9999;
  bottom: 100px;
`

export const DropdownTitle = styled.div`
  font-family: "Cabinet Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.neutral[30]};
`

export const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-height: 260px;
  overflow: auto;
  gap: ${({ theme }) => theme.spacings.xs3};
`

const dropdownVariantStyle = {
  ["active"]: css`
    background-color: ${({ theme }) => theme.colors.secondary[95]};
    border-color: ${({ theme }) => theme.colors.secondary[70]};
    color: ${({ theme }) => theme.colors.secondary[50]};
    font-weight: 700;
  `,
  ["default"]: css`
    background-color: ${({ theme }) => theme.colors.neutral[100]};
    border-color: ${({ theme }) => theme.colors.neutral[90]};
    color: ${({ theme }) => theme.colors.neutral[50]};
  `,
  ["secondary"]: css`
    background-color: ${({ theme }) => theme.colors.neutral[100]};
    border-color: ${({ theme }) => theme.colors.neutral[90]};
    color: ${({ theme }) => theme.colors.neutral[50]};

    > svg {
      color: ${({ theme }) => theme.colors.secondary[50]};
    }
  `,
}

export const DropdownItem = styled.div<{
  variant: "active" | "default" | "secondary"
}>`
  width: 100%;
  height: 40px;
  padding: ${({ theme }) => `${theme.spacings.xs4} ${theme.spacings.xs2}`}
  margin: ${({ theme }) => theme.spacings.xs5} 0;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;

  border-width: ${({ theme }) => theme.borders.width.xs2};
  border-style: solid;
  border-radius: ${({ theme }) => theme.borders.radius.s};

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  gap: ${({ theme }) => theme.spacings.xs3};
  padding: ${({ theme }) => theme.spacings.xs3} ${({ theme }) => theme.spacings.xs2};

  ${({ variant }) => dropdownVariantStyle[variant]}
`

export const DropdownItemLabel = styled.span`
  margin-left: 4;
`
