import { createContext, ReactNode, useContext, useState } from "react"

export function createFormContext<T>() {
  const FormContext = createContext<{
    formValues: T | null
    setFormValues: (values: T) => void
  } | null>(null)

  const FormProvider = ({ children }: { children: ReactNode }) => {
    const [formValues, setFormValues] = useState<T | null>(null)
    return (
      <FormContext.Provider value={{ formValues, setFormValues }}>
        {children}
      </FormContext.Provider>
    )
  }

  const useFormContext = () => {
    const context = useContext(FormContext)
    if (!context) {
      throw new Error("useFormContext must be used within a FormProvider")
    }
    return context
  }

  return { FormProvider, useFormContext }
}
