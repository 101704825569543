import { trpc } from "@api/client"
import {
  ExitFormEnum,
  GetResignationResponse,
  InvoluntaryTerminationReason,
  VoluntaryTerminationReason,
} from "@customTypes/resignation"
import { useDebouncedFunction } from "@hooks/useDebouncedFunction"
import i18n from "@i18n"
import dispatchToast from "@utils/dispatchToast"
import React from "react"

const voluntaryReasonMapper: Record<VoluntaryTerminationReason, string> = {
  [VoluntaryTerminationReason.Compensation]: i18n.t(
    "page.interview.reasonOptions.compensation",
  ),
  [VoluntaryTerminationReason.Benefits]: i18n.t(
    "page.interview.reasonOptions.benefits",
  ),
  [VoluntaryTerminationReason.CulturalFit]: i18n.t(
    "page.interview.reasonOptions.culturalFit",
  ),
  [VoluntaryTerminationReason.LackOfGrowthOpportunity]: i18n.t(
    "page.interview.reasonOptions.lackOfGrowthOpportunity",
  ),
  [VoluntaryTerminationReason.CareerChange]: i18n.t(
    "page.interview.reasonOptions.careerChange",
  ),
  [VoluntaryTerminationReason.MisalignedActivities]: i18n.t(
    "page.interview.reasonOptions.misalignedActivities",
  ),
  [VoluntaryTerminationReason.LackOfManagementSupport]: i18n.t(
    "page.interview.reasonOptions.lackOfManagementSupport",
  ),
  [VoluntaryTerminationReason.ManagementChange]: i18n.t(
    "page.interview.reasonOptions.managementChange",
  ),
  [VoluntaryTerminationReason.LackOfRecognition]: i18n.t(
    "page.interview.reasonOptions.lackOfRecognition",
  ),
  [VoluntaryTerminationReason.AreaChange]: i18n.t(
    "page.interview.reasonOptions.areaChange",
  ),
  [VoluntaryTerminationReason.Other]: i18n.t(
    "page.interview.reasonOptions.other",
  ),
}

const involuntaryReasonMapper: Record<InvoluntaryTerminationReason, string> = {
  [InvoluntaryTerminationReason.Behavior]: i18n.t(
    "page.interview.reasonOptions.behavior",
  ),
  [InvoluntaryTerminationReason.Performance]: i18n.t(
    "page.interview.reasonOptions.performance",
  ),
  [InvoluntaryTerminationReason.DisciplinaryAction]: i18n.t(
    "page.interview.reasonOptions.disciplinaryAction",
  ),
  [InvoluntaryTerminationReason.LackOfCulturalFit]: i18n.t(
    "page.interview.reasonOptions.lackOfCulturalFit",
  ),
  [InvoluntaryTerminationReason.LackOfAdaptation]: i18n.t(
    "page.interview.reasonOptions.lackOfAdaptation",
  ),
  [InvoluntaryTerminationReason.StrategyOrStructureChange]: i18n.t(
    "page.interview.reasonOptions.strategyOrStructureChange",
  ),
  [InvoluntaryTerminationReason.InternshipCompletion]: i18n.t(
    "page.interview.reasonOptions.internshipCompletion",
  ),
}

const reasonMapper: Record<ExitFormEnum, Record<string, string>> = {
  [ExitFormEnum.Voluntary]: voluntaryReasonMapper,
  [ExitFormEnum.Involuntary]: involuntaryReasonMapper,
}

export function useInterview({
  resignationId,
  exitForm,
  initialInterview,
}: {
  resignationId: string
  exitForm: GetResignationResponse["exitForm"]
  initialInterview: GetResignationResponse["interview"]
}) {
  const [interview, setInterview] = React.useState(initialInterview)

  const delayFn = useDebouncedFunction(1000)

  const { mutateAsync: updateInterview } =
    trpc.resignation.updateInterview.useMutation({
      onError: (error) => {
        dispatchToast({
          type: "error",
          content:
            error.data?.userFriendlyError.message ??
            i18n.t("error.internalServerError"),
        })
      },
    })

  const handleUpdateInterview = async (
    body: Parameters<typeof updateInterview>[0]["body"],
  ) => {
    setInterview({
      ...interview,
      ...body,
    })

    updateInterview({
      resignationId,
      body,
    })
  }

  const reasonOptions = Object.entries(reasonMapper[exitForm]).map(
    ([value, label]) => ({
      label,
      value,
    }),
  )

  return {
    interview,
    reasonOptions,
    handleUpdateInterview,
    delayFn,
  }
}
