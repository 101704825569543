import { MultiSelect } from "@flash-tecnologia/hros-web-ui-v2"

import { SelectOption } from "@customTypes"
import { useEmployeeOptions } from "@hooks/useEmployeeOptions"
import React from "react"
import { trpc } from "@api/client"
import { Skeleton } from "@mui/material"

type EmployeeMultiSelectProps = {
  onSelectChange: (options: SelectOption[]) => void
  loadingText: string
  label: string
  placeholder?: string
  invalidSearchText: string
  noOptionsText: string
  initialValues?: string[]
}

export const EmployeeMultiSelect = ({
  onSelectChange,
  loadingText,
  label,
  placeholder,
  invalidSearchText,
  noOptionsText,
  initialValues = [],
}: EmployeeMultiSelectProps) => {
  const [selectedOption, setSelectedOption] = React.useState<SelectOption[]>([])
  const [firstTimeLoading, setFirstTimeLoading] = React.useState(true)

  const [employeeSearchTerm, setEmployeeSearchTerm] = React.useState("")

  const { data: employeeSelectedList } =
    trpc.employee.findEmployeesByIds.useQuery(
      { ids: initialValues },
      { enabled: firstTimeLoading },
    )

  const {
    employeeOptions,
    isLoading: isEmployeeOptionsLoading,
    debouncedSearchTerm: debouncedEmployeeSearchTerm,
  } = useEmployeeOptions({
    searchTerm: employeeSearchTerm,
    employeeIdsToFilter: initialValues ?? [],
  })

  React.useEffect(() => {
    if (firstTimeLoading) {
      if (initialValues.length > 0 && !employeeSelectedList) return
      if (initialValues.length > 0 && employeeSelectedList) {
        setSelectedOption(
          employeeSelectedList?.map((employee) => ({
            label: employee.name,
            value: employee.id,
          })) ?? [],
        )
      }

      setFirstTimeLoading(false)
    }
  }, [employeeSelectedList])

  if (firstTimeLoading) {
    return <Skeleton variant="rectangular" height={40} width="100%" />
  }

  return (
    <MultiSelect
      renderInput={() => null}
      value={selectedOption}
      onChange={(e) => {
        setEmployeeSearchTerm(e.target.value)
      }}
      onSelectChange={(_, options: SelectOption[] | null) => {
        setSelectedOption(options ?? [])
        onSelectChange(options ?? [])
      }}
      loadingText={loadingText}
      label={label}
      placeholder={placeholder}
      noOptionsText={
        debouncedEmployeeSearchTerm?.length < 3
          ? invalidSearchText
          : isEmployeeOptionsLoading
            ? loadingText
            : noOptionsText
      }
      options={employeeOptions}
      fullWidth
    />
  )
}
