import {
  EvaluationStatus,
  ExitFormEnum,
  ExitTermsEnum,
  FormalizationStatus,
  Steps,
} from "@customTypes/resignation"

export default {
  translations: {
    page: {
      home: {
        title: "Desligamento",
        buttons: {
          refreshKanban: "Atualizar kanban",
          upsertEmployee: "Selecionar pessoa",
        },
        kanban: {
          cardsCountDescription:
            "<display>{{cardsCount}}</display> <description>pessoa(s) na etapa</description>",
          requestDateLabel: "Data da solicitação:",
        },
      },
      resignationStep: {
        [Steps.Initiated]: {
          title: "Em espera",
          subtitle:
            "Acesse os dados do colaborador e inicie o processo de desligamento de forma fácil e rápida.",
          stepDetailsSection: {
            title: "Dados do desligamento",
            description:
              "Preencha os campos abaixo de acordo com o tipo de desligamento.",
            fields: {
              requestedBy: {
                label: "Solicitado por",
              },
              exitForm: {
                label: "Tipo de desligamento",
              },
              exitTerms: {
                label: "Tipo de rescisão contratual",
              },
              email: {
                label: "E-mail pessoal",
              },
              requestDate: {
                label: "Data de pedido de demissão",
              },
              complyWithNoticePeriod: {
                label: "Irá cumprir aviso prévio?",
                yes: "Sim",
                no: "Não",
              },
              noticePeriodDays: {
                label: "Quantidade de dias de aviso prévio",
                description: "{{noticePeriodDays}} dia(s)",
              },
              terminationDate: {
                label: "Último dia de trabalho efetivo",
              },
              motivation: {
                label: "Motivações do desligamento",
              },
              emptyField: {
                notFound: "Não cadastrado",
                notFilled: "Não preenchido",
              },
            },
          },
        },
        [Steps.Evaluation]: {
          title: "Entrevista de desligamento",
          subtitle:
            "Você pode optar pela entrevista de desligamento, preenchendo os tópicos discutidos, ou pelo envio de uma pesquisa de desligamento com o módulo de engajamento.",
          stepDetailsSection: {
            organizationTitle: "Organização da entrevista",
            organizationDescription:
              "Você pode optar pela entrevista de desligamento, preenchendo os tópicos discutidos, ou pelo envio de uma pesquisa de desligamento com o módulo de engajamento.",
            dateInterviewLabel: "Data da entrevista",
            responsiblesLabel: "Responsáveis pela entrevista",
            responsiblesPlaceholder: "Digite o nome da pessoa",
            responsiblesLoadingText: "Buscando responsáveis...",
            responsiblesNoOptionText:
              "Não foi encontrada nenhuma pessoa com esse nome.",
            responsiblesInvalidSearchText:
              "Digite ao menos 3 letras do nome da pessoa.",
            notesTitle: "Anotações da entrevista",
            notesDescription:
              "Anexe um arquivo de texto ou digite diretamente pela plataforma.",
            notesLabel: "Anotações da entrevista",
            attachmentLabel: "Adicionar anotações da entrevista",
            attachmentPlaceholder: "Anotações da entrevista",
            reasonLabel: "Justificativa do desligamento",
            reasonOptions: {
              compensation: "Remuneração",
              benefits: "Benefícios",
              culturalFit: "Identificação com a cultura",
              lackOfGrowthOpportunity: "Falta de perspectiva de crescimento",
              careerChange: "Mudança de carreira",
              misalignedActivities: "Atividades desalinhadas com a contratação",
              lackOfManagementSupport: "Falta de acompanhamento da gestão",
              managementChange: "Mudança da gestão",
              lackOfRecognition: "Falta de reconhecimento",
              areaChange: "Mudança de área",
              other: "Outro",
              behavior: "Comportamento",
              performance: "Performance",
              disciplinaryAction: "Ação Disciplinar (Justa Causa)",
              lackOfCulturalFit: "Falta de identificação com a cultura",
              lackOfAdaptation: "Falta de adaptação",
              strategyOrStructureChange: "Mudança de Estratégia/Estrutura",
              internshipCompletion: "Efetivação (em caso de estágio)",
            },
          },
        },
        [Steps.Formalization]: {
          title: "Formalização",
          reset: {
            button: "Reiniciar etapa",
            success: "Etapa reiniciada com sucesso!",
            modal: {
              highlight: "Atenção",
              title: "Tem certeza que deseja reiniciar a etapa?",
              description: "Ao reiniciar a etapa, todo o processo retornará ao estado inicial. Todas as informações preenchidas serão perdidas e essa ação não poderá ser desfeita.",
              footer: {
                cancel: "Cancelar",
                submit: "Reiniciar etapa"
              }
            }
          },
          subtitle:
            "Formalize o desligamento solicitando a carta se necessário e envie instruções de próximos passos.",
          submit: {
            button: {
              emailInstructions: "Enviar e-mail de formalização",
              requestLetter: "Solicitar carta de desligamento",
            },
            messages: {
              invalidLetterSectionForm:
                "Verifique os campos necessários da carta de desligamento antes de prosseguir",
              invalidEmailInstructionsForm:
                "Verifique os campos necessários de próximos passos antes de prosseguir",
              sendInstructionsSuccess: "E-mail enviado com sucesso!",
              requestLetterSuccess:
                "Solicitação de carta de desligamento enviada com sucesso",
              missingEmail: "Preencha o endereço de e-mail da pessoa",
              missingLetterChoice:
                "Selecione a opção que faz mais sentido para a carta de desligamento da pessoa",
              invalidForm:
                "Verifique os campos necessários antes de enviar o e-mail de formalização",
            },
          },
          letterSection: {
            title: "Carta de desligamento",
            description:
              "Selecione a opção que faz mais sentido para a carta de desligamento da pessoa.",
            form: {
              fields: {
                shouldRequestLetter: {
                  label: "Solicitar carta de desligamento",
                  description:
                    "Escolha esta opção para solicitar a carta de desligamento do colaborador.",
                },
                deadline: {
                  label: "Data limite para envio da carta de desligamento",
                },
                alreadyHaveRequestLetter: {
                  label: "Já tenho a carta de desligamento",
                  description:
                    "Se você já tiver a carta de desligamento, faça o upload diretamente aqui.",
                },
                requestLetter: {
                  label: "Adicionar carta de desligamento",
                  uploader: {
                    placeholder: "Carta de desligamento",
                  },
                },
              },
              validation: {
                errors: {
                  required: "Campo obrigatório",
                  exclusiveCheck:
                    "Escolha a opção que faz mais sentido para a carta de desligamento da pessoa",
                },
              },
            },
          },
          emailInstructionsSection: {
            addInstructions: {
              label: "Adicionar instruções no e-mail",
              description:
                "Envie um e-mail de próximos passos que a pessoa deve realizar no seu desligamento.",
              submitButton: "Enviar instruções no e-mail",
            },
            requestLetter: {
              submitButton: "Solicitar carta de desligamento",
            },
            notificationCard: {
              title: "E-mail enviado com sucesso!",
              subtitle:
                "E-mail enviado às 18:45 do dia 18/04/2024. Você pode editar as informações e reenviar o e-mail quando quiser",
            },
            form: {
              fields: {
                email: {
                  label: "Endereço de e-mail da pessoa",
                  description:
                    "Escolha esta opção para solicitar a carta de desligamento do colaborador.",
                },
              },
              validation: {
                errors: {
                  required: "Campo obrigatório",
                  invalidEmail: "E-mail inválido",
                  invalidForm:
                    "Verifique os campos do formulário antes de seguir",
                },
              },
              messages: {
                success: "E-mail enviado com sucesso!",
              },
            },
            uploader: {
              label: "Adicionar próximos passos",
              placeholder: "Próximos passos",
              helperText:
                "Caso queira, adicione um anexo em PDF de intruções para próximos passos que a pessoa deve fazer para continuar com seu processo de desligamento.",
              defaultFileName: "Arquivo de instruções",
              messages: {
                error: {
                  download:
                    "Erro ao fazer o download do arquivo. Tente novamente mais tarde!",
                },
              },
            },
          },
        },
        [Steps.ExitExam]: {
          title: "Exame demissional",
          subtitle: "Anexe o exame demissional da pessoa.",
        },
        [Steps.Signature]: {
          title: "Assinatura da rescisão",
          subtitle:
            "Faça o envio dos documentos rescisórios e acompanhe o status da assinatura.",
        },
        [Steps.Done]: {
          title: "Concluído",
          subtitle: "Pessoas com o processo de desligamento concluído.",
        },
        [Steps.Archived]: {
          title: "Arquivado",
          subtitle:
            "Pessoas que foram arquivadas no meio do processo de desligamento.",
        },
        navigation: {
          home: "Desligamento",
        },
        body: {
          header: {
            disclaimer: "Fase atual",
            definitionList: {
              status: "Status",
              responsible: "Responsável",
              followers: "Acompanhantes",
            },
          },
        },
        footer: {
          back: "Voltar",
          jumpStep: "Mover para outra etapa",
          nextStep: "Mover para próxima etapa",
          stepChangedSuccessfully: "Desligamento movimentado com sucesso!",
          moveStepTitle: "Mover card para fase",
        },
        messages: {
          resignationNotFound:
            "Os dados deste desligamento não foram encontrados. Tente novamente mais tarde!",
        },
        card: {
          personDetails: {
            pillButtons: {
              share: "Compartilhar",
              archive: "Arquivar",
              edit: "Editar",
              copyClipboardSuccess: "Link copiado para a área de transferência",
              archiveSuccess: "Desligamento arquivado com sucesso",
              alreadyArchived: "Desligamento já está arquivado.",
            },
            labels: {
              fullName: "Nome completo",
              cpf: "CPF",
              registration: "Matrícula",
              leader: "Líder",
              email: "E-mail",
              phone: "Celular",
              contractType: "Tipo de contrato",
              admissionDate: "Data de admissão",
              communicationDate: "Comunicação de desligamento",
            },
            values: {
              default: "Não preenchido",
            },
          },
        },
      },
      components: {
        archiveModal: {
          highlight: "Atenção",
          title: "Tem certeza que deseja arquivar o desligamento?",
          description:
            "Ao finalizar, todas as informações do processo de desligamento serão desconsideradas e o colaborador não será inativado.",
          buttons: {
            cancel: "Cancelar",
            confirm: "Arquivar colaborador",
          },
        },
        personDetails: {
          tab: {
            information: "Pessoa",
            historic: "Histórico",
          },
        },
        detailItem: {
          notFilled: "Não preenchido",
        },
        resignationStatusTag: {
          [EvaluationStatus.NotStarted]: "Não iniciado",
          [EvaluationStatus.InProgress]: "Em andamento",
          [EvaluationStatus.Done]: "Concluído",
          [FormalizationStatus.PendingEmail]: "Envio de e-mail pendente",
          [FormalizationStatus.InProgress]: "Em andamento",
          [FormalizationStatus.Done]: "Concluído",
        },
        upload: {
          loadingTitle: "Carregando o arquivo, por favor, aguarde!",
          errorArchiveSize:
            "Este arquivo ultrapassa o limite máximo. Por favor, faça o upload de um arquivo menor.",
          errorName: "Nome não identificado",
          errorMultipleFiles: "Apenas um arquivo é permitido.",
          errorUpload: "Erro ao fazer o upload do arquivo.",
          loadingLabel: {
            progress: "Enviando arquivo...",
            finished: "Arquivo enviado com sucesso!",
          },
          errorLabel: "Erro no envio do seu arquivo.",
          errorRetryButton: "Tentar novamente",
        },
      },
      types: {
        exitForm: {
          [ExitFormEnum.Involuntary]: "Involuntário",
          [ExitFormEnum.Voluntary]: "Voluntário",
        },
        exitTerms: {
          [ExitTermsEnum.ForCause]: "Demissão por justa causa",
          [ExitTermsEnum.MutualAgreement]: "Demissão consensual",
          [ExitTermsEnum.Resignation]: "Pedido de demissão pela pessoa",
          [ExitTermsEnum.WithoutCause]: "Demissão sem justa causa",
        },
      },
    },
    components: {
      upsertEmployeeModal: {
        title: "Selecionar pessoa",
        description:
          "Selecione uma pessoa e inclua-a no fluxo de desligamento\nNão se preocupe, elas não serão notificadas sobre isso por enquanto",
        buttons: {
          open: "Selecionar pessoa",
          submit: "Selecionar pessoa",
          cancel: "Cancelar",
        },
        form: {
          employee: {
            label: "Selecione a pessoa",
            invalidSearchText: "Digite ao menos 3 letras do nome da pessoa.",
            noOptionText: "Não foi encontrada nenhuma pessoa com esse nome.",
            loadingText: "Buscando pessoa...",
          },
          responsible: {
            label: "Responsável pelo desligamento",
            invalidSearchText: "Digite ao menos 3 letras do nome da pessoa.",
            noOptionText: "Não foi encontrada nenhuma pessoa com esse nome.",
            loadingText: "Buscando pessoa...",
          },
          followers: {
            label: "Acompanhantes do desligamento",
            invalidSearchText: "Digite ao menos 3 letras do nome da pessoa.",
            noOptionText: "Não foi encontrada nenhuma pessoa com esse nome.",
            loadingText: "Buscando pessoa...",
          },
          exitForm: {
            label: "Tipo de desligamento",
            values: {
              [ExitFormEnum.Voluntary]: "Voluntário",
              [ExitFormEnum.Involuntary]: "Involuntário",
            },
          },
          exitTerms: {
            label: "Tipo de rescisão contratual",
            values: {
              [ExitTermsEnum.WithoutCause]: "Demissão sem justa causa",
              [ExitTermsEnum.ForCause]: "Demissão por justa causa",
              [ExitTermsEnum.MutualAgreement]: "Demissão consensual",
              [ExitTermsEnum.Resignation]: "Pedido de demissão pela pessoa",
            },
          },
          email: "E-mail pessoal",
          requestDate: "Data do pedido de desligamento",
          noticePeriod: "Irá cumprir aviso prévio",
          terminationDate: "Último dia de trabalho efetivo",
          motivation: "Motivações do desligamento",
          errors: {
            required: "Campo obrigatório",
            employeeIsAlreadyAdded:
              "Pessoa indisponível devido a desligamento em andamento ou concluído.",
            invalidResponsible:
              "Pessoa selecionada não pode ser a responsável pelo desligamento.",
            responsibleIsOnResignation:
              "Responsável indisponível devido a desligamento em andamento ou concluído.",
            followerIsOnResignation:
              "Acompanhante indisponível devido a desligamento em andamento ou concluído.",
            checkFields: "Verifique os campos do formulário.",
          },
        },
      },
    },
    error: {
      internalServerError:
        "Ocorreu um problema no sistema, por favor tente novamente mais tarde.",
    },
  },
}
