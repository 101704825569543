import { ReactElement } from "react"
import { Header } from "./Header"
import { LeftContent } from "./LeftContent"
import { RightContent } from "./RightContent"
import * as SC from "./styles"

export interface RootProps {
  children: ReactElement<typeof Header | typeof LeftContent | typeof RightContent>[]
}

const Root = ({ children }: RootProps) => {
  return <SC.PageBodyWrapper>{children}</SC.PageBodyWrapper>
}

export const PageBody = {
  Header,
  LeftContent,
  RightContent,
  Root,
}
