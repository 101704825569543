import { Box } from "@atoms"
import { ReactNode } from "react"
import { useTheme } from "styled-components"

export interface FooterProps {
  children: ReactNode
}

export const Footer = ({ children }: FooterProps) => {
  const theme = useTheme()

  return (
    <Box $px="xs" $py="xs2" $borderTop={`${theme.borders.width.xs2} solid ${theme.colors.neutral[90]}`}>
      {children}
    </Box>
  )
}
