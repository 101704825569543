import { Box, Card } from "@atoms"
import { Upload } from "@molecules"
import {
  GetResignationResponse,
  Evaluation as EvaluationType,
  File as FileType,
  Steps,
} from "@customTypes/resignation"
import {
  DatePicker,
  Divider,
  SelectField,
  TextEditor,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import { useTranslation } from "react-i18next"
import { useEvaluation } from "./hooks/useEvaluation"
import { SelectOption } from "@customTypes"
import { EmployeeMultiSelect } from "@molecules"

interface EvaluationProps {
  resignationId: string
  exitForm: GetResignationResponse["exitForm"]
  initialEvaluation: GetResignationResponse["evaluation"]
}

export const Evaluation = ({
  resignationId,
  exitForm,
  initialEvaluation,
}: EvaluationProps) => {
  const { t } = useTranslation("translations", {
    keyPrefix: `page.resignationStep.${Steps.Evaluation}.stepDetailsSection`,
  })

  const { evaluation, reasonOptions, handleUpdateEvaluation, delayFn } =
    useEvaluation({
      resignationId,
      exitForm,
      initialEvaluation,
    })

  return (
    <Card>
      <Box $width="100%" flexDirection="column" gap="xs">
        <div>
          <Typography variant="headline8" color="neutral.30">
            {t("organizationTitle")}
          </Typography>
          <Typography variant="body4" color="neutral.50">
            {t("organizationDescription")}
          </Typography>
        </div>

        <DatePicker
          label={t("dateInterviewLabel")}
          value={evaluation?.date}
          onDateChange={(date) => {
            delayFn(() => {
              handleUpdateEvaluation({ date: date?.toDate() })
            })
          }}
          required
        />

        <EmployeeMultiSelect
          initialValues={evaluation?.responsiblesIds ?? []}
          onSelectChange={(options) => {
            handleUpdateEvaluation({
              responsiblesIds: options.map(({ value }) => value),
            })
          }}
          label={t("responsiblesLabel")}
          loadingText={t("responsiblesLoadingText")}
          invalidSearchText={t("responsiblesInvalidSearchText")}
          noOptionsText={t("responsiblesNoOptionText")}
        />

        <Divider orientation="horizontal" />

        <div>
          <Typography variant="headline8" color="neutral.30">
            {t("notesTitle")}
          </Typography>
          <Typography variant="body4" color="neutral.50">
            {t("notesDescription")}
          </Typography>
        </div>

        <TextEditor
          placeholder={t("notesLabel")}
          value={evaluation?.notes}
          onChange={(notes) => {
            delayFn(() => {
              handleUpdateEvaluation({ notes })
            })
          }}
          styles={{
            editor: {
              height: "150px",
            },
          }}
        />

        <Upload.Root
          folder="interview"
          label={t("attachmentLabel")}
          placeholder={t("attachmentPlaceholder")}
          value={evaluation?.attachment}
          onUpload={async (file: FileType) => {
            handleUpdateEvaluation({ attachment: file })
          }}
          accept={["txt", "jpg", "jpeg", "png", "pdf"]}
        >
          <Upload.Action
            icon={"IconTrash"}
            onClick={() => {
              handleUpdateEvaluation({ attachment: undefined })
            }}
          />
        </Upload.Root>

        <SelectField
          label={t("reasonLabel")}
          options={reasonOptions}
          value={evaluation?.reason}
          onSelectChange={(e, value: SelectOption) => {
            delayFn(() => {
              handleUpdateEvaluation({
                reason: value.value as Required<EvaluationType>["reason"],
              })
            })
          }}
          fullWidth
          required
        />
      </Box>
    </Card>
  )
}
