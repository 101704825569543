import { Box } from "@atoms"
import { useTheme } from "styled-components"
import { Detail } from "./Detail"

type DetailsWrapperProps = {
  children: React.ReactElement<typeof Detail>[]
}

export const DetailsWrapper = ({ children }: DetailsWrapperProps) => {
  const theme = useTheme()

  return (
    <Box
      $width="100%"
      flexDirection="column"
      gap="xs2"
      $mt="xs"
      $pt="m"
      $borderTop={`${theme.borders.width.xs2} solid ${theme.colors.neutral[90]}`}
    >
      {children}
    </Box>
  )
}
