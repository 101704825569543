import { Root } from "./Root"
import { Picture } from "./Picture"
import { Name } from "./Name"
import { ActionsWrapper } from "./ActionsWrapper"
import { Action } from "./Action"
import { DetailsWrapper } from "./DetailsWrapper"
import { Detail } from "./Detail"

export default {
  Root,
  Picture,
  Name,
  ActionsWrapper,
  Action,
  DetailsWrapper,
  Detail,
}
