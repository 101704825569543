import { Box } from "@atoms"
import { Action } from "./Action"

type ActionsProps = {
  children: React.ReactElement<typeof Action>[]
}

export const ActionsWrapper = ({ children }: ActionsProps) => {
  return (
    <Box flexDirection="row" gap="xs2">
      {children}
    </Box>
  )
}
