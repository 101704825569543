import { trpc } from "@api/client"
import { Card, FlowColumn } from "@customTypes/flow"
import { Steps } from "@customTypes/resignation"
import i18n from "@i18n"
import { KanbanColumnType } from "@organisms/Kanban/Column"
import { dayjs } from "@utils/dayjs"
import dispatchToast from "@utils/dispatchToast"
import { isNil } from "lodash-es"
import { useEffect } from "react"
import { DropResult } from "react-beautiful-dnd"
import { useTranslation } from "react-i18next"
import { generatePath, useNavigate } from "react-router-dom"
import { ResignationCardProps } from "../ResignationCard"
import { mapResignationStatusToTagProps } from "../utils/mapResignationStatusToTagProps"
import React from "react"

export interface KanbanFlowCard extends ResignationCardProps {
  id: string
  onClick?: () => void
}

export interface KanbanFlowColumn {
  id: string
  title: string
  description: string
  columnType: KanbanColumnType
  cards: KanbanFlowCard[]
}

const mapFlowColumnIdToKanbanColumnType: Partial<
  Record<Steps, KanbanColumnType>
> = {
  [Steps.Archived]: KanbanColumnType.Neutral,
}

export function useResignationKanban() {
  const [archiveModal, setArchiveModal] = React.useState<{
    open: boolean
    callback?: () => Promise<void>
  }>({ open: false })

  const utils = trpc.useUtils()

  const navigate = useNavigate()

  const [t] = useTranslation("translations", {
    keyPrefix: "page.home.kanban",
  })

  const {
    isLoading: isGetFlowLoading,
    data: flowColumns,
    error: getFlowError,
  } = trpc.flow.getFlow.useQuery()

  const removeDraggedCardFromFlowColumn = (
    flowColumn: FlowColumn,
    draggedCardId: string,
  ) => {
    const cardsWithoutPreviousCard = flowColumn.cards.filter(
      (card) => card.id !== draggedCardId,
    )

    const sortedCards = cardsWithoutPreviousCard.sort(
      (a, b) => a.card.position - b.card.position,
    )

    return {
      ...flowColumn,
      cards: sortedCards.map((card, index) => ({
        ...card,
        card: {
          position: index,
        },
      })),
    }
  }

  const addDraggedCardToFlowColumn = (
    flowColumn: FlowColumn,
    draggedCard: Card,
    position: number,
  ) => {
    const sortedCards = flowColumn.cards.sort(
      (a, b) => a.card.position - b.card.position,
    )
    const mergedCards = [
      ...sortedCards.slice(0, position),
      draggedCard,
      ...sortedCards.slice(position),
    ]
    return {
      ...flowColumn,
      cards: mergedCards.map((card, index) => ({
        ...card,
        card: {
          position: index,
        },
      })),
    }
  }

  const { mutateAsync: changePosition, error: changePositionError } =
    trpc.flow.changePosition.useMutation({
      onMutate: async ({ resignationId, position }) => {
        await utils.flow.getFlow.cancel()

        const previousDataSnapshot = utils.flow.getFlow.getData()

        if (!previousDataSnapshot) return { previousFlowColumns: undefined }

        const { currentFlowColumn, draggedCard } =
          previousDataSnapshot?.reduce<{
            currentFlowColumn?: FlowColumn
            draggedCard?: Card
          }>((acc, flowColumn) => {
            const card = flowColumn.cards.find(
              (card) => card.id === resignationId,
            )
            if (card) {
              acc.currentFlowColumn = flowColumn
              acc.draggedCard = card
            }
            return acc
          }, {}) ?? {}

        if (!currentFlowColumn || !draggedCard)
          return { previousFlowColumns: previousDataSnapshot }

        const updatedFlowColumns = previousDataSnapshot?.map((flowColumn) => {
          const shouldAddDraggedCard =
            flowColumn.id === currentFlowColumn.id && draggedCard
          if (shouldAddDraggedCard) {
            const sortedCards = currentFlowColumn.cards.sort(
              (a, b) => a.card.position - b.card.position,
            )

            const filteredCards = sortedCards.filter(
              (card) => card.id !== draggedCard.id,
            )

            const mergedCards = [
              ...filteredCards.slice(0, position),
              draggedCard,
              ...filteredCards.slice(position),
            ]

            return {
              ...flowColumn,
              cards: mergedCards.map((card, index) => ({
                ...card,
                card: {
                  position: index,
                },
              })),
            }
          }
          return flowColumn
        })
        utils.flow.getFlow.setData(undefined, updatedFlowColumns)
        return { previousFlowColumns: previousDataSnapshot }
      },
      onError: (_, __, context) => {
        if (context?.previousFlowColumns) {
          utils.flow.getFlow.setData(undefined, context.previousFlowColumns)
        }
      },
      onSettled: () => {
        utils.flow.getFlow.invalidate()
      },
    })

  const { mutateAsync: changeStep, error: changeStepError } =
    trpc.flow.changeStep.useMutation({
      onMutate: async ({ resignationId, step, position }) => {
        await utils.flow.getFlow.cancel()

        const previousDataSnapshot = utils.flow.getFlow.getData()

        if (!previousDataSnapshot) return { previousFlowColumns: undefined }

        const { previousFlowColumn, draggedCard } =
          previousDataSnapshot.reduce<{
            previousFlowColumn?: FlowColumn
            draggedCard?: Card
          }>((acc, flowColumn) => {
            const draggedCard = flowColumn.cards.find(
              (card) => card.id === resignationId,
            )
            if (draggedCard) {
              acc.previousFlowColumn = flowColumn
              acc.draggedCard = draggedCard
            }
            return acc
          }, {})

        if (!previousFlowColumn || !draggedCard)
          return { previousFlowColumns: previousDataSnapshot }

        const updatedFlowColumns = previousDataSnapshot.map(
          (currentFlowColumn) => {
            const shouldRemoveDraggedCard =
              currentFlowColumn.id === previousFlowColumn.id

            if (shouldRemoveDraggedCard) {
              return removeDraggedCardFromFlowColumn(
                currentFlowColumn,
                draggedCard.id,
              )
            }

            const shouldMoveDraggedCardToCurrentColumn =
              currentFlowColumn.id === step

            if (shouldMoveDraggedCardToCurrentColumn) {
              return addDraggedCardToFlowColumn(
                currentFlowColumn,
                draggedCard,
                position,
              )
            }
            return currentFlowColumn
          },
        )
        utils.flow.getFlow.setData(undefined, updatedFlowColumns)
        return { previousFlowColumns: previousDataSnapshot }
      },
      onError: (_, __, context) => {
        if (context?.previousFlowColumns) {
          utils.flow.getFlow.setData(undefined, context.previousFlowColumns)
        }
      },
      onSettled: () => {
        utils.flow.getFlow.invalidate()
      },
    })
  useEffect(() => {
    if (!!getFlowError) {
      dispatchToast({
        content:
          getFlowError.data?.userFriendlyError?.message ??
          i18n.t("error.internalServerError"),
        type: "error",
      })
    }
  }, [getFlowError])

  useEffect(() => {
    if (!!changeStepError) {
      dispatchToast({
        content:
          changeStepError.data?.userFriendlyError?.message ??
          i18n.t("error.internalServerError"),
        type: "error",
      })
    }
  }, [changeStepError])

  useEffect(() => {
    if (!!changePositionError) {
      dispatchToast({
        content:
          changePositionError.data?.userFriendlyError?.message ??
          i18n.t("error.internalServerError"),
        type: "error",
      })
    }
  }, [changePositionError])

  const handleKanbanDragEnd = (dropResult: DropResult) => {
    const source = dropResult.source
    const destination = dropResult.destination
    if (!destination) return

    const targetResignationId = dropResult.draggableId
    const nextColumn: Steps | undefined = (
      Object.values(Steps) as string[]
    ).includes(destination.droppableId)
      ? (destination.droppableId as Steps)
      : undefined

    if (nextColumn && source.droppableId !== destination.droppableId) {
      if (destination.droppableId === Steps.Archived) {
        setArchiveModal({
          open: true,
          callback: async () => {
            await changeStep({
              resignationId: targetResignationId,
              position: destination.index,
              step: nextColumn,
            })
          },
        })
        return
      }

      changeStep({
        resignationId: targetResignationId,
        position: destination.index,
        step: nextColumn,
      })
    } else if (!isNil(destination?.index)) {
      changePosition({
        resignationId: targetResignationId,
        position: destination?.index,
      })
    }
  }

  const handleCloseArchiveModal = () => {
    setArchiveModal({
      open: false,
    })
  }

  const kanbanFlowColumns = flowColumns?.map<KanbanFlowColumn>((flowColumn) => {
    const orderedCards = flowColumn.cards.sort(
      (a, b) => a.card.position - b.card.position,
    )

    return {
      id: flowColumn.id,
      title: flowColumn.name,
      description: flowColumn.description,
      columnType:
        mapFlowColumnIdToKanbanColumnType[flowColumn.id] ??
        KanbanColumnType.Primary,
      cards: orderedCards.map<KanbanFlowCard>((card) => {
        const tagProps = mapResignationStatusToTagProps(
          (card as Partial<Record<Steps, { status?: string }>>)[card.step]
            ?.status ?? "",
        )

        const resignationPageRouterPath = generatePath(
          "/resignation/step/:resignationId",
          {
            resignationId: card.id,
          },
        )
        return {
          id: card.id,
          bottomText: `${t("requestDateLabel")} ${dayjs(card.requestDate).format("DD/MM/YYYY")}`,
          onClick: () => navigate(resignationPageRouterPath, { replace: true }),
          subtitle: card.email ?? "",
          title: card.employeeName,
          name: card.employeeName,
          tagProps,
        }
      }),
    }
  })

  return {
    kanbanFlowColumns,
    isLoading: isGetFlowLoading,
    onKanbanDragEnd: handleKanbanDragEnd,
    archiveModal,
    onArchiveModalClose: handleCloseArchiveModal,
  }
}
