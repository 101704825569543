import { trpc } from "@api/client"
import { Dropzone } from "@flash-tecnologia/hros-web-ui-v2"
import { uploadFileToS3 } from "@flash-tecnologia/hros-web-utility"
import i18n from "@i18n"
import dispatchToast from "@utils/dispatchToast"
import React, { ComponentProps } from "react"
import { UploadProps } from "."

const TEN_MEGABYTES = 10 * 1024 * 1024

function isFileSizeValid(file: File) {
  return file.size <= TEN_MEGABYTES
}

export enum States {
  Idle = "idle",
  Loading = "loading",
  Error = "error",
  Preview = "preview",
}

type UseUploadProps = {
  error?: UploadProps["error"]
  value: UploadProps["value"]
  folder: UploadProps["folder"]
  onUpload: UploadProps["onUpload"]
}

export const useUpload = ({
  error,
  value,
  folder,
  onUpload,
}: UseUploadProps) => {
  const [state, setState] = React.useState<States>(
    !!value ? States.Preview : States.Idle,
  )
  const [temporaryUploadInformation, setTemporaryUploadInformation] =
    React.useState({
      progress: 0,
      archiveName: "",
    })

  const { mutateAsync: getPresignedUrlMutate } =
    trpc.utils.getPresignedUrl.useMutation()

  React.useEffect(() => {
    if (value && temporaryUploadInformation.progress === 100) {
      setTimeout(() => {
        setState(States.Preview)
        setTemporaryUploadInformation({
          archiveName: "",
          progress: 0,
        })
      }, 3000)
    }
  }, [value, temporaryUploadInformation])

  React.useEffect(() => {
    if (state === States.Preview && !value) {
      setState(States.Idle)
    }
  }, [value, state])

  const handleChange: ComponentProps<typeof Dropzone>["onChange"] = async (
    files,
  ) => {
    if (files.length > 1) {
      dispatchToast({
        content: i18n.t("page.components.upload.errorMultipleFiles"),
        type: "error",
      })
      setState(States.Error)
      return
    }

    const file = files[0]
    if (!isFileSizeValid(file.file)) {
      dispatchToast({
        content: i18n.t("page.components.upload.errorArchiveSize"),
        type: "error",
      })
      setState(States.Error)
      return
    }

    setTemporaryUploadInformation((prev) => ({
      ...prev,
      archiveName: file.file.name,
    }))

    try {
      const path = `${folder}/${Date.now()}-${file.file.name}`
      const url = await getPresignedUrlMutate({
        path,
        command: "put",
      })
      await uploadFileToS3({
        file: file.file,
        url,
        onProgress: (percentage) => {
          setTemporaryUploadInformation((prev) => ({
            ...prev,
            progress: percentage,
          }))
        },
      })
      onUpload?.(
        {
          path,
        },
        file.file.name,
      )
    } catch (err) {
      setState(States.Error)
      dispatchToast({
        content: i18n.t("page.components.upload.errorUpload"),
        type: "error",
      })
    }
  }

  const handleRetry = () => {
    setTemporaryUploadInformation({
      progress: 0,
      archiveName: "",
    })
    setState(States.Idle)
  }

  React.useEffect(() => {
    if (error) {
      setState(States.Error)
    }
  }, [error])

  return {
    state,
    temporaryUploadInformation,
    setTemporaryUploadInformation,
    handleChange,
    handleRetry,
  }
}
