import { Typography } from "@flash-tecnologia/hros-web-ui-v2"

export interface DefinitionTitleProps {
  children: string
}

export const DefinitionTitle = ({ children }: DefinitionTitleProps) => {
  return (
    <Typography.Caption color="neutral.60" weight={600}>
      {children}
    </Typography.Caption>
  )
}
