import { trpc } from "@api/client"
import {
  ExitFormEnum,
  GetResignationResponse,
  InvoluntaryTerminationReason,
  Steps,
  VoluntaryTerminationReason,
} from "@customTypes/resignation"
import { useDebouncedFunction } from "@hooks/useDebouncedFunction"
import i18n from "@i18n"
import dispatchToast from "@utils/dispatchToast"
import React from "react"

const voluntaryReasonMapper: Record<VoluntaryTerminationReason, string> = {
  [VoluntaryTerminationReason.Compensation]: i18n.t(
    `page.resignationStep.${Steps.Evaluation}.stepDetailsSection.reasonOptions.compensation`,
  ),
  [VoluntaryTerminationReason.Benefits]: i18n.t(
    `page.resignationStep.${Steps.Evaluation}.stepDetailsSection.reasonOptions.benefits`,
  ),
  [VoluntaryTerminationReason.CulturalFit]: i18n.t(
    `page.resignationStep.${Steps.Evaluation}.stepDetailsSection.reasonOptions.culturalFit`,
  ),
  [VoluntaryTerminationReason.LackOfGrowthOpportunity]: i18n.t(
    `page.resignationStep.${Steps.Evaluation}.stepDetailsSection.reasonOptions.lackOfGrowthOpportunity`,
  ),
  [VoluntaryTerminationReason.CareerChange]: i18n.t(
    `page.resignationStep.${Steps.Evaluation}.stepDetailsSection.reasonOptions.careerChange`,
  ),
  [VoluntaryTerminationReason.MisalignedActivities]: i18n.t(
    `page.resignationStep.${Steps.Evaluation}.stepDetailsSection.reasonOptions.misalignedActivities`,
  ),
  [VoluntaryTerminationReason.LackOfManagementSupport]: i18n.t(
    `page.resignationStep.${Steps.Evaluation}.stepDetailsSection.reasonOptions.lackOfManagementSupport`,
  ),
  [VoluntaryTerminationReason.ManagementChange]: i18n.t(
    `page.resignationStep.${Steps.Evaluation}.stepDetailsSection.reasonOptions.managementChange`,
  ),
  [VoluntaryTerminationReason.LackOfRecognition]: i18n.t(
    `page.resignationStep.${Steps.Evaluation}.stepDetailsSection.reasonOptions.lackOfRecognition`,
  ),
  [VoluntaryTerminationReason.AreaChange]: i18n.t(
    `page.resignationStep.${Steps.Evaluation}.stepDetailsSection.reasonOptions.areaChange`,
  ),
  [VoluntaryTerminationReason.Other]: i18n.t(
    `page.resignationStep.${Steps.Evaluation}.stepDetailsSection.reasonOptions.other`,
  ),
}

const involuntaryReasonMapper: Record<InvoluntaryTerminationReason, string> = {
  [InvoluntaryTerminationReason.Behavior]: i18n.t(
    `page.resignationStep.${Steps.Evaluation}.stepDetailsSection.reasonOptions.behavior`,
  ),
  [InvoluntaryTerminationReason.Performance]: i18n.t(
    `page.resignationStep.${Steps.Evaluation}.stepDetailsSection.reasonOptions.performance`,
  ),
  [InvoluntaryTerminationReason.DisciplinaryAction]: i18n.t(
    `page.resignationStep.${Steps.Evaluation}.stepDetailsSection.reasonOptions.disciplinaryAction`,
  ),
  [InvoluntaryTerminationReason.LackOfCulturalFit]: i18n.t(
    `page.resignationStep.${Steps.Evaluation}.stepDetailsSection.reasonOptions.lackOfCulturalFit`,
  ),
  [InvoluntaryTerminationReason.LackOfAdaptation]: i18n.t(
    `page.resignationStep.${Steps.Evaluation}.stepDetailsSection.reasonOptions.lackOfAdaptation`,
  ),
  [InvoluntaryTerminationReason.StrategyOrStructureChange]: i18n.t(
    `page.resignationStep.${Steps.Evaluation}.stepDetailsSection.reasonOptions.strategyOrStructureChange`,
  ),
  [InvoluntaryTerminationReason.InternshipCompletion]: i18n.t(
    `page.resignationStep.${Steps.Evaluation}.stepDetailsSection.reasonOptions.internshipCompletion`,
  ),
}

const reasonMapper: Record<ExitFormEnum, Record<string, string>> = {
  [ExitFormEnum.Voluntary]: voluntaryReasonMapper,
  [ExitFormEnum.Involuntary]: involuntaryReasonMapper,
}

export function useEvaluation({
  resignationId,
  exitForm,
  initialEvaluation,
}: {
  resignationId: string
  exitForm: GetResignationResponse["exitForm"]
  initialEvaluation: GetResignationResponse["evaluation"]
}) {
  const [evaluation, setEvaluation] = React.useState(initialEvaluation)

  const delayFn = useDebouncedFunction(1000)

  const { mutateAsync: updateEvaluation } =
    trpc.resignation.updateEvaluation.useMutation({
      onError: (error) => {
        dispatchToast({
          type: "error",
          content:
            error.data?.userFriendlyError.message ??
            i18n.t("error.internalServerError"),
        })
      },
    })

  const handleUpdateEvaluation = async (
    body: Parameters<typeof updateEvaluation>[0]["body"],
  ) => {
    setEvaluation({
      ...evaluation,
      ...body,
    })

    updateEvaluation({
      resignationId,
      body,
    })
  }

  const reasonOptions = Object.entries(reasonMapper[exitForm]).map(
    ([value, label]) => ({
      label,
      value,
    }),
  )

  return {
    evaluation,
    reasonOptions,
    handleUpdateEvaluation,
    delayFn,
  }
}
