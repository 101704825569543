import { Container, EllipsisTypography } from "./styles"
import { Skeleton } from "@mui/material"
import { useTranslation } from "react-i18next"

export const Error = () => {
  const [t] = useTranslation("translations", {
    keyPrefix: "page.components.upload",
  })

  return (
    <Container>
      <Skeleton variant="rectangular" width={45} height={45} />

      <EllipsisTypography variant="body3" weight={700} color="neutral.30">
        {t("errorName")}
      </EllipsisTypography>
    </Container>
  )
}
