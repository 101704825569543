import React from "react"

export const useDebouncedFunction = (timeoutMs?: number) => {
  const timeoutRef = React.useRef<any>()

  return (callback: () => any) => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current)

    timeoutRef.current = setTimeout(async () => {
      return await callback()
    }, timeoutMs ?? 500)
  }
}
