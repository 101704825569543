import { Typography } from "@flash-tecnologia/hros-web-ui-v2"
import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacings.s} ${({ theme }) => theme.spacings.m};
  border: 1px solid ${({ theme }) => theme.colors.neutral[90]};
  border-radius: ${({ theme }) => theme.borders.radius.m};
`

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  width: 100%;
  margin-right: ${({ theme }) => theme.spacings.xs4};
  gap: ${({ theme }) => theme.spacings.xs3};
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;
`

export const ProgressBar = styled.div`
  width: 100%;
  height: 4px;
  background: ${({ theme }) => theme.colors.primary};
  border-radius: ${({ theme }) => theme.borders.radius.m};
  margin-top: ${({ theme }) => theme.spacings.xs3};

  animation-duration: 6s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: wave;
  animation-timing-function: linear;
  background: linear-gradient(
    to right,
    ${({ theme }) => theme.colors.primary} 50%,
    #da0b6d 80%
  );
`

export const EllipsisTypography = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
