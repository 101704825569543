import { File, Steps } from "@customTypes/resignation"
import { Dayjs } from "@flash-tecnologia/hros-web-ui-v2"
import { useFormik } from "formik"
import { ForwardedRef, useEffect, useImperativeHandle } from "react"
import { useTranslation } from "react-i18next"
import * as yup from "yup"
import { useLetterFormContext } from "../components/FormalizationStep"
import { LetterForm, LetterSectionProps } from "../components/LetterSection"
import { FormRef } from "./useFormalizationStep"

export function useLetterForm({
  initialAlreadyHaveLetter,
  initialLetter,
  initialSendingLetterDeadline,
  initialShouldRequestLetter,
  letterFormRef,
}: {
  letterFormRef: ForwardedRef<FormRef<LetterForm>>
} & LetterSectionProps) {
  const [t] = useTranslation("translations", {
    keyPrefix: `page.resignationStep.${Steps.Formalization}.letterSection`,
  })

  const { setFormValues } = useLetterFormContext()

  const formik = useFormik<LetterForm>({
    initialValues: {
      alreadyHaveLetter: initialAlreadyHaveLetter,
      letter: initialLetter,
      shouldRequestLetter: initialShouldRequestLetter,
      sendingLetterDeadline: initialSendingLetterDeadline,
    },
    onSubmit: () => {},
    validationSchema: yup
      .object({
        sendingLetterDeadline: yup.date().when("shouldRequestLetter", {
          is: true,
          then: (schema) =>
            schema.required(t("form.validation.errors.required")),
          otherwise: (schema) => schema.notRequired(),
        }),
        alreadyHaveLetter: yup.boolean(),
        shouldRequestLetter: yup.boolean(),
      })
      .test(
        "exclusive-check",
        t("form.validation.errors.exclusiveCheck"),
        function (this: yup.TestContext, values) {
          const { alreadyHaveLetter, shouldRequestLetter } = values

          if (alreadyHaveLetter === shouldRequestLetter) {
            return this.createError({
              path: "alreadyHaveLetter",
              message: t("form.validation.errors.exclusiveCheck"),
            })
          }
          return true
        },
      ),
    validateOnBlur: true,
    validateOnChange: false,
  })

  useImperativeHandle(
    letterFormRef,
    () => ({
      validate: formik.validateForm,
    }),
    [formik.validateForm],
  )

  const handleSendingLetterDeadlineBlur = () =>
    formik.handleBlur({
      target: { name: "sendingLetterDeadline" },
    })

  const handleSendingLetterDeadlineChange = (value?: Dayjs) => {
    formik.handleChange({
      target: { name: "sendingLetterDeadline", value: value?.toDate() },
    })
    formik.handleBlur({ target: { name: "sendingLetterDeadline" } })
  }

  const handleShouldRequestLetterChange = (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    formik.handleChange({
      target: { name: "shouldRequestLetter", value: checked },
    })

    formik.handleChange({
      target: { name: "alreadyHaveLetter", value: !checked },
    })

    formik.setFieldError("alreadyHaveLetter", undefined)
  }

  const handleAlreadyHaveLetterChange = (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    formik.handleChange({
      target: { name: "alreadyHaveLetter", value: checked },
    })

    formik.handleChange({
      target: { name: "shouldRequestLetter", value: !checked },
    })

    formik.setFieldError("alreadyHaveLetter", undefined)
  }

  const handleLetterUpload = async (file: File) => {
    formik.handleChange({ target: { name: "letter", value: file } })
  }

  const handleLetterDelete = () => {
    formik.handleChange({
      target: { name: "letter", value: undefined },
    })
  }

  useEffect(() => {
    setFormValues(formik.values)
  }, [setFormValues, formik.values])

  return {
    alreadyHaveLetter: formik.values.alreadyHaveLetter,
    alreadyHaveLetterErrorMessage: formik.errors.alreadyHaveLetter,
    letter: formik.values.letter,
    letterErrorMessage: formik.errors.letter,
    sendingLetterDeadline: formik.values.sendingLetterDeadline,
    sendingLetterDeadlineErrorMessage: formik.errors.sendingLetterDeadline,
    shouldRequestLetter: formik.values.shouldRequestLetter,
    onAlreadyHaveLetterChange: handleAlreadyHaveLetterChange,
    onLetterDelete: handleLetterDelete,
    onLetterUpload: handleLetterUpload,
    onSendingLetterDeadlineChange: handleSendingLetterDeadlineChange,
    onSendingLetterDeadlineBlur: handleSendingLetterDeadlineBlur,
    onShouldRequestLetterChange: handleShouldRequestLetterChange,
  }
}
