import { ReactNode } from "react"
import * as SC from "./styles"

export interface BodyProps {
  children: ReactNode
}

export const Body = ({ children }: BodyProps) => {
  return <SC.BodyWrapper>{children}</SC.BodyWrapper>
}
