export const downloadFile = async (value: string | URL, fileName: string) => {
    const readFile = await fetch(value, {
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
    })
  
    const fileToArrayBuffer = await readFile.arrayBuffer()
    const res = URL.createObjectURL(new Blob([fileToArrayBuffer]))
    const a = document.createElement("a")
    a.href = res
    a.download = fileName
    a.click()
  
    URL.revokeObjectURL(res)
  }
  