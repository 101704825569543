import styled, { css } from "styled-components"

export const TabContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr 1fr;
  width: 100%;

  padding: ${({ theme }) => theme.spacings.xs4};
  border: ${({ theme }) => theme.borders.width.xs2} solid
    ${({ theme }) => theme.colors.neutral[90]};
  border-radius: ${({ theme }) => theme.borders.radius.l};
`

export const TabButton = styled.button<{ active: boolean }>`
  font-weight: 700;
  font-size: 16px;
  padding: ${({ theme }) => theme.spacings.xs3}
    ${({ theme }) => theme.spacings.m};
  color: ${({ theme }) => theme.colors.neutral[40]};
  border-radius: ${({ theme }) => theme.borders.radius.m};

  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.colors.secondary[95]};
      border: ${({ theme }) => theme.borders.width.xs2} solid
        ${({ theme }) => theme.colors.secondary[70]};
      color: ${({ theme }) => theme.colors.secondary[40]};
    `}
`
