import { Box } from "@atoms"
import { Skeleton, Typography } from "@flash-tecnologia/hros-web-ui-v2"
import i18n from "@i18n"
import PersonDetails from "@organisms/PersonDetails"
import { usePersonDetails } from "@pages/ResignationStep/hooks/usePersonDetails"
import { useState } from "react"
import { TabButton, TabContainer } from "./styles"

enum TabEnum {
  Information = "information",
  Historic = "historic",
}

const tabNames: Record<TabEnum, string> = {
  [TabEnum.Information]: i18n.t(
    "page.components.personDetails.tab.information",
  ),
  [TabEnum.Historic]: i18n.t("page.components.personDetails.tab.historic"),
}

interface PersonProps {
  communicationDate?: Date
  employeeId: string
}

export const Historic = () => {
  return (
    <Box
      $width="100%"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap="m"
    >
      <Typography.Body1 color="neutral.50">To be implemented</Typography.Body1>
    </Box>
  )
}

const Person = ({ communicationDate, employeeId }: PersonProps) => {
  const { isLoading, profilePicture, personDetails } = usePersonDetails({
    communicationDate,
    employeeId,
  })

  if (isLoading) {
    return (
      <Box $width={"100%"} flexDirection="column">
        <Box
          $width="100%"
          gap="xs2"
          flexDirection="column"
          alignItems="center"
          $px="m"
          $mb="m"
        >
          <Skeleton variant="circular" width={"150px"} height={"150px"} />
          <Skeleton variant="rectangular" width={"60%"} height={"30px"} />
          <Skeleton variant="rectangular" width={"30%"} height={"40px"} />
        </Box>
        <Box flexDirection="column" gap="xs">
          <Skeleton variant="rectangular" width={"50%"} height={"50px"} />
          <Skeleton variant="rectangular" width={"50%"} height={"50px"} />
          <Skeleton variant="rectangular" width={"50%"} height={"50px"} />
          <Skeleton variant="rectangular" width={"50%"} height={"50px"} />
          <Skeleton variant="rectangular" width={"50%"} height={"50px"} />
        </Box>
      </Box>
    )
  }

  return (
    <>
      <PersonDetails.Root>
        <PersonDetails.Picture
          name={personDetails.fullName.value ?? ""}
          source={profilePicture ?? ""}
        />
        <PersonDetails.Name name={personDetails.fullName.value ?? ""} />
        <PersonDetails.ActionsWrapper>
          <PersonDetails.Action icon="IconPencil" onClick={() => {}} />
          <PersonDetails.Action icon="IconArchive" onClick={() => {}} />
          <PersonDetails.Action icon="IconShare" onClick={() => {}} />
        </PersonDetails.ActionsWrapper>
        <PersonDetails.DetailsWrapper>
          {Object.entries(personDetails).map(
            ([key, { icon, label, enableCopy, type, value }]) => (
              <PersonDetails.Detail
                enableCopy={enableCopy}
                key={key}
                icon={icon}
                label={label}
                type={type}
                value={value}
              />
            ),
          )}
        </PersonDetails.DetailsWrapper>
      </PersonDetails.Root>
    </>
  )
}

interface BodyRightContentProps {
  communicationDate?: Date
  employeeId: string
}

export const BodyRightContent = ({
  communicationDate,
  employeeId,
}: BodyRightContentProps) => {
  const [tab, setTab] = useState<TabEnum>(TabEnum.Information)

  return (
    <Box
      $width="100%"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap="m"
      $mb="m"
    >
      <TabContainer>
        {Object.values(TabEnum).map((tabValue) => (
          <TabButton active={tab === tabValue} onClick={() => setTab(tabValue)}>
            {tabNames[tabValue]}
          </TabButton>
        ))}
      </TabContainer>

      {tab === TabEnum.Information && (
        <Person communicationDate={communicationDate} employeeId={employeeId} />
      )}
      {tab === TabEnum.Historic && <Historic />}
    </Box>
  )
}
