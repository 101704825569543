import { trpc } from "@api/client"
import { SelectOption } from "@customTypes"
import { useDebouncedValue } from "@hooks"
import { useEffect, useState } from "react"

type UseEmployeeOptionsParams = {
  searchTerm: string
  employeeIdsToFilter?: string[]
}

export const useEmployeeOptions = ({ searchTerm, employeeIdsToFilter }: UseEmployeeOptionsParams) => {
  const searchTermDebounced = useDebouncedValue({ inputValue: searchTerm })

  const [employeeOptions, setEmployeeOptions] = useState<SelectOption[]>([])

  const { data: employeeList, isFetching } = trpc.employee.findEmployeesByPartialName.useQuery(
    { searchParam: searchTermDebounced },
    { enabled: searchTermDebounced.length > 2 },
  )

  useEffect(() => {
    setEmployeeOptions(
      employeeList
        ?.filter(({ id }) => !employeeIdsToFilter?.includes(id))
        ?.map(({ name, id }) => ({ label: name, value: id })) ?? [],
    )
  }, [employeeList])

  return {
    employeeList,
    employeeOptions,
    setEmployeeOptions,
    isLoading: isFetching,
    debouncedSearchTerm: searchTermDebounced,
  }
}
