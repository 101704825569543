import {
  ProgressBar,
  ShapeIcon,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2"
import { useTheme } from "styled-components"
import { Container, ProgressContainer } from "./styles"
import { Box, EllipsisTypography } from "@atoms"
import i18n from "@i18n"
import { useProgressAnimation } from "@hooks"

export enum States {
  progress = "progress",
  finished = "finished",
}

type LoadingProps = {
  archiveName: string
  progress: number
  updateDuration?: number
}

const mapLabel: Record<States, string> = {
  [States.progress]: i18n.t("page.components.upload.loadingLabel.progress"),
  [States.finished]: i18n.t("page.components.upload.loadingLabel.finished"),
}

export const Loading = ({
  archiveName,
  progress,
  updateDuration = 2000,
}: LoadingProps) => {
  const theme = useTheme()
  const animatedProgress = useProgressAnimation(progress, updateDuration)

  const state: States =
    animatedProgress !== 100 ? States.progress : States.finished

  return (
    <Container state={state}>
      <ShapeIcon
        name="IconUpload"
        variant={state === States.finished ? "success" : "default"}
        color={
          state === States.finished
            ? theme.colors.feedback.success[40]
            : theme.colors.secondary[50]
        }
        size={64}
      />

      <Box
        flexDirection="column"
        gap="xs5"
        alignItems="center"
        $mt="xs3"
        $mb="xs"
      >
        <EllipsisTypography variant="body3" weight={700} color="neutral.30">
          {archiveName}
        </EllipsisTypography>
        <Typography variant="body3" weight={400} color="neutral.30">
          {mapLabel[state]}
        </Typography>
      </Box>

      <ProgressContainer>
        <ProgressBar
          hideLabel={false}
          variant={state === States.finished ? "success" : "default"}
          progress={animatedProgress}
        />
      </ProgressContainer>
    </Container>
  )
}
